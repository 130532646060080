import { constructionProgressApiV1 as constructionProgressApi } from '@deepup/apis';
import { listEnumNumbers } from '@protobuf-ts/runtime';
import { DateTime } from 'luxon';
import { LngLat } from 'mapbox-gl';
import { type Dispatch, type SetStateAction } from 'react';
import { useLocation } from 'react-router-dom';
import { z } from 'zod';

import { useSearchParamsExtended } from '@hooks/useSearchParamsExtended';
import { camelToKebab } from '@utils/core';

export enum TrassFilterEnum {
  USAGE,
  LAYING,
  SURFACE,
}

// TODO: how to get rid of this doubling? converting enum to const array does not work
//  and creating a reverse mapped object from array seems a little overkill
export const trassFilterValues = ['USAGE', 'LAYING', 'SURFACE'] as const;
export type TrassFilter = (typeof trassFilterValues)[number];

type CapitalizeFirstLetter<S extends string> = S extends `${infer F}${infer R}`
  ? `${Uppercase<F>}${R}`
  : S;

const getSecondsForDayFrom = (dateTime: DateTime) => {
  return dateTime.startOf('day').set({ millisecond: 0 }).toSeconds().toString();
};

const maxWidth = 100;
const maxDepth = 100;

const getSecondsForDayTo = (dateTime: DateTime) => {
  return dateTime.endOf('day').set({ millisecond: 0 }).toSeconds().toString();
};

const zEnumNumberGet = (e: Record<string | number, unknown>) =>
  z.enum(listEnumNumbers(e).map(String) as unknown as [string, ...string[]]).transform(Number);

const zSizeGet = z.coerce
  .number()
  .int()
  .min(0)
  .max(100)
  .nullable()
  .catch(() => null);

const zSizeSet = (transformer: (v: number) => string | null) =>
  z.coerce.number().int().transform(transformer).nullable();

const zBooleanGet = (fallback: boolean) =>
  z
    .enum(['true', 'false'])
    .transform((value) => value === 'true')
    .nullable()
    .transform((v) => v ?? fallback)
    .catch(() => fallback);

const zBooleanSet = (fallback: boolean) =>
  z.coerce.boolean().transform((v) => (v === fallback ? null : String(v)));

const zArrayGet = z
  .string()
  .transform((v) => v.split(','))
  .pipe(z.string().array())
  .nullable()
  .transform((v) => v ?? []);

const zArraySet = z
  .array(z.string())
  .transform((v) => (Array.isArray(v) ? v.join(',') : null))
  .transform((v) => v || null);

const zDateGet = z.coerce
  .number()
  .int()
  .nullable()
  .transform((v) => (v ? DateTime.fromSeconds(v) : null))
  .catch(() => null);

const zDateSet = (fromTo: 'from' | 'to') =>
  z
    .custom<DateTime>()
    .nullable()
    .transform((v) =>
      v
        ? DateTime.isDateTime(v) && v.isValid
          ? fromTo === 'from'
            ? getSecondsForDayFrom(v)
            : getSecondsForDayTo(v)
          : null
        : null,
    );

const zLngLatGet = z.coerce
  .string()
  .nullable()
  .transform((v) => {
    const match = v?.match(/^\w+\((-?\d+\.\d+), ?(-?\d+\.\d+)\)$/);

    if (match) {
      return new LngLat(parseFloat(match[1]), parseFloat(match[2]));
    }

    return null;
  })
  .catch(() => null);

const zLngLatSet = z
  .custom<LngLat>()
  .nullable()
  .transform((v) => (v && v instanceof LngLat ? v.toString() : null));

export const FilterSchemaGet = z.object({
  // common
  sidebar: z
    .literal('fullscreen')
    .nullable()
    .catch(() => null),

  // filters
  projectId: z
    .string()
    .nullable()
    .catch(() => null),

  organizationId: z
    .string()
    .nullable()
    .catch(() => null),

  dateRange: z.object({
    from: zDateGet,
    to: zDateGet,
  }),
  scanDevices: zArrayGet,
  projectList: zArrayGet,
  interval: zEnumNumberGet(constructionProgressApi.Interval).catch(
    () => constructionProgressApi.Interval.MONTHLY,
  ),

  // layers
  showScans: zBooleanGet(true),
  showPhotos: zBooleanGet(false),
  photoCategories: zArrayGet,
  showPrelabels: zBooleanGet(false),
  minPrelabelDepth: zSizeGet,
  depth: z.object({
    min: zSizeGet,
    max: zSizeGet,
  }),
  width: z.object({
    min: zSizeGet,
    max: zSizeGet,
  }),
  showTrasses: zBooleanGet(false),
  activeTrassFilter: z
    .enum(trassFilterValues)
    .nullable()
    .transform((v) => v ?? ('USAGE' as TrassFilter))
    .catch(() => 'USAGE' as TrassFilter),
  usageTypes: zArrayGet,
  layingTypes: zArrayGet,
  surfaceTypes: zArrayGet,
  satelliteView: zBooleanGet(false),
  showPlandata: zBooleanGet(false),
  surfaceClassification: zArrayGet,
  marker: zLngLatGet,
});

export const FilterSchemaSet = z.object({
  // common
  sidebar: z
    .custom<'fullscreen'>()
    .transform((v) => (v === 'fullscreen' ? 'fullscreen' : null))
    .nullable(),

  // filters
  projectId: z.coerce.string().nullable(),

  organizationId: z.coerce.string().nullable(),

  dateRange: z.object({
    from: zDateSet('from'),
    to: zDateSet('to'),
  }),
  scanDevices: zArraySet,
  projectList: zArraySet,
  interval: z.custom<constructionProgressApi.Interval>(),

  // layers
  showScans: zBooleanSet(true),
  showPhotos: zBooleanSet(false),
  photoCategories: zArraySet,
  showPrelabels: zBooleanSet(false),
  minPrelabelDepth: zSizeSet((v) => (v <= 0 ? null : String(Math.min(maxDepth, v)))),
  depth: z.object({
    min: zSizeSet((v) => (v <= 0 ? null : String(Math.min(maxDepth, v)))),
    max: zSizeSet((v) => (v >= 100 ? null : String(Math.max(v, 0)))),
  }),
  width: z.object({
    min: zSizeSet((v) => (v <= 0 ? null : String(Math.min(v, maxWidth)))),
    max: zSizeSet((v) => (v >= 100 ? null : String(Math.max(0, v)))),
  }),
  showTrasses: zBooleanSet(false),
  activeTrassFilter: z
    .custom<TrassFilter>()
    .transform((v) => (!trassFilterValues.includes(v) ? null : v))
    .nullable(),
  usageTypes: zArraySet,
  layingTypes: zArraySet,
  surfaceTypes: zArraySet,
  satelliteView: zBooleanSet(false),
  showPlandata: zBooleanSet(false),
  surfaceClassification: zArraySet,
  marker: zLngLatSet,
});

export type FilterStates = ReturnType<(typeof FilterSchemaGet)['parse']>;
type FilterStatesSet = z.input<typeof FilterSchemaSet>;

type Setter = {
  [K in keyof FilterStatesSet as `set${CapitalizeFirstLetter<string & K>}`]: Dispatch<
    SetStateAction<FilterStatesSet[K]>
  >;
};

// TODO: make this generic setter work inside getSetter
//  it's a little tricky, because we need z.input<T> and not just T
// type SetterGeneric<T> = {
//   [K in keyof T as `set${CapitalizeFirstLetter<string & K>}`]: Dispatch<SetStateAction<T[K]>>;
// };

export type FilterValues = Setter & FilterStates & { reset: () => void };

export const useFilters = (): FilterValues => {
  const [searchParams, setSearchParams] = useSearchParamsExtended();
  const location = useLocation();

  const getter = getGetter(FilterSchemaGet, (key: string) => searchParams.get(key));
  const setter = getSetter(
    FilterSchemaSet,
    (values, excludeKeys) =>
      setSearchParams(values, {
        preserveHash: true,
        preserveSearch: true,
        preserveSearchExclude: excludeKeys,
      }),
    getter,
  );

  // TODO: only reset own filters
  const reset = () => {
    setSearchParams(new URLSearchParams(), {
      preserveSearch: false,
      preserveHash: false,
    });

    // hack to empty location.search
    // is this a bug in react-router, that location.search is not updated correctly after navigate?
    location.search = '';
  };

  return {
    ...getter,
    ...setter,
    reset,
  };
};

export const getDataToParse = <T extends z.ZodRawShape>(
  schema: z.ZodObject<T>,
  getValue: (key: string) => string | null,
) => {
  return Object.entries(schema.shape).reduce((acc, [key, value]) => {
    const urlParamKey = camelToKebab(key);

    if (value instanceof z.ZodObject) {
      const keys = Object.entries(value.shape).reduce((acc, [key]) => {
        const urlParamKeyForChild = `${urlParamKey}-${camelToKebab(key)}`;

        return { ...acc, [key]: getValue(urlParamKeyForChild) };
      }, {});

      return { ...acc, [key]: keys };
    }

    return { ...acc, [key]: getValue(urlParamKey) };
  }, {});
};

export const getGetter = <T extends z.ZodRawShape>(
  schema: z.ZodObject<T>,
  getValue: (key: string) => string | null,
) => {
  const toParse = getDataToParse(schema, getValue);

  return schema.parse(toParse);
};

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getSetter = <T extends z.ZodRawShape>(
  schema: z.ZodObject<T>,
  setValues: (values: URLSearchParams, excludeKeys?: string[]) => void,
  prevValues: FilterStates,
): Setter => {
  return Object.entries(schema.shape).reduce((acc, [key, value]) => {
    const urlParamKey = camelToKebab(key);

    return {
      ...acc,
      [`set${capitalizeFirstLetter(key)}`]: (
        input: typeof value | ((prev: typeof value) => typeof value),
      ) => {
        const parsedValue =
          typeof input === 'function'
            ? value.parse(input(prevValues[key as keyof FilterStates] as unknown as z.ZodTypeAny))
            : value.parse(input);

        if (parsedValue === null) {
          setValues(new URLSearchParams(), [urlParamKey]);

          return;
        }

        if (typeof parsedValue === 'object') {
          const values = Object.entries(parsedValue).reduce(
            (acc, [innerKey, value]) => {
              const urlParamKeyForChild = `${urlParamKey}-${camelToKebab(innerKey)}`;

              if (value === null) {
                return {
                  values: { ...acc.values },
                  exclude: [...acc.exclude, urlParamKeyForChild],
                };
              }

              return {
                values: { ...acc.values, [urlParamKeyForChild]: value },
                exclude: [...acc.exclude],
              };
            },
            { values: {}, exclude: [] as string[] },
          );

          setValues(new URLSearchParams(values.values), values.exclude);

          return;
        }

        setValues(new URLSearchParams({ [urlParamKey]: parsedValue }));
      },
    };
  }, {} as Setter);
};
