import mapboxgl from 'mapbox-gl';
import { useCallback, useEffect, useState } from 'react';
import { useMap } from 'react-map-gl';

import { useMapEvent } from '@hooks/useMapEvent';
import { wait } from '@utils/wait';

export const useMapZoomIsAbove = (threshold: number, delayInMs = 0) => {
  const [isZoomAboveThreshold, setIsZoomAboveThreshold] = useState(false);
  const { current: map } = useMap();

  const handleMapboxZoom = async ({ target: map }: { target: mapboxgl.Map }) => {
    await wait(delayInMs);
    setIsZoomAboveThreshold(map.getZoom() > threshold);
  };
  const zoomCallback = useCallback(handleMapboxZoom, [delayInMs, threshold]);

  // change value when map changes + use for initial value
  useEffect(() => map && setIsZoomAboveThreshold(map.getZoom() > threshold), [threshold, map]);

  useMapEvent('zoom', zoomCallback);

  return {
    isZoomAboveThreshold,
  };
};
