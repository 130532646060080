import { Autocomplete, TextField } from '@mui/material';

import type { Item } from '@components/FilterBox/fields/MultiSelect';

type SingleSelectBaseProps = {
  label: string;
  items: Item[];
  helperText?: string;
  disabled?: boolean;
  selectedItem: Item | null;
  setSelectedItem: (nextSelected: Item | null) => void;
};

export const SingleSelect = ({
  label,
  items,
  selectedItem,
  setSelectedItem,
  helperText = '',
  disabled,
}: SingleSelectBaseProps) => {
  return (
    <Autocomplete
      disabled={disabled}
      fullWidth
      getOptionLabel={(option) => option.name}
      onChange={(_, nextItem) => {
        setSelectedItem(nextItem);
      }}
      options={items}
      renderInput={(params) => (
        <TextField {...params} helperText={helperText} label={label} placeholder={label} />
      )}
      slotProps={{ paper: { elevation: 6 } }}
      value={selectedItem}
    />
  );
};
