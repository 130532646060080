import { RpcRestError } from '@deepup/api-utils';
import {
  constructionProgressApiV1 as constructionProgressApi,
  constructionProgressClientApiV1 as constructionProgressClientApi,
} from '@deepup/apis';
import type { GridPaginationModel } from '@mui/x-data-grid';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { useGrpcRestTransport } from '@hooks/useGrpcRestTransport';
import { mapSum } from '@utils/core';
import { toTimeRange } from '@utils/timeFormatting';
import type { DateRange } from '@utils/types';

export const pageSize = 20;

export type GetTimeSeriesResponse = constructionProgressApi.GetTimeSeriesResponse;
export type TimeSeries = constructionProgressApi.TimeSeries;

export const mergePagedTimeSeries = (pages?: GetTimeSeriesResponse[]): TimeSeries[] =>
  pages?.[0]?.items.map(({ startsAt }, i) => ({
    startsAt,
    totalMeters: mapSum(pages, (page) => page.items[i]?.totalMeters ?? 0),
    progress: pages.flatMap((page) => page.items[i]?.progress ?? []),
  })) ?? [];

export const useApiTimeSeries = (
  projectIds: string[],
  scanDeviceIds: string[],
  dateRange: DateRange,
  interval: constructionProgressApi.Interval,
) => {
  const transport = useGrpcRestTransport();

  const res = useInfiniteQuery<constructionProgressApi.GetTimeSeriesResponse, RpcRestError>({
    enabled:
      // ATTENTION: interval is always defined thus not part of the condition.
      !!projectIds.length &&
      // !!scanDeviceIds.length && // TODO enable scan device filtering in next iteration!
      dateRange.from?.isValid &&
      dateRange.to?.isValid,
    queryKey: [
      'constructionProgress',
      'timeSeries',
      projectIds,
      scanDeviceIds,
      dateRange,
      interval,
    ],
    initialPageParam: 0n,
    queryFn: ({ pageParam }) => {
      const constructionClient =
        new constructionProgressClientApi.ConstructionProgressServiceClient(transport);

      const request: constructionProgressApi.GetTimeSeriesRequest = {
        options: {
          withPaginationInfo: true,
          contextId: '', // non-optional. Needs to be set to empty string. Yes, this is not clean!
          pageSize,
          page: pageParam as bigint,
        },
        timeRange: toTimeRange(dateRange),
        interval,
        projectIds,
        scanDeviceIds,
      };

      return constructionClient.getTimeSeries(request).response;
    },
    getNextPageParam: ({ paginationInfo }) =>
      paginationInfo && paginationInfo.page < paginationInfo.totalPages - 1n
        ? paginationInfo.page + 1n
        : undefined,
    staleTime: Infinity,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
  });

  return { ...res, items: mergePagedTimeSeries(res.data?.pages) };
};

export const useApiTotalProgress = (
  projectIds: string[],
  scanDeviceIds: string[],
  dateRange: DateRange,
) => {
  const [page, setPage] = useState(0n);
  const transport = useGrpcRestTransport();

  const res = useQuery<constructionProgressApi.GetTotalProgressResponse, RpcRestError>({
    enabled:
      !!projectIds.length &&
      // !!scanDeviceIds.length && // TODO enable scan device filtering in next iteration!
      dateRange.from?.isValid &&
      dateRange.to?.isValid,
    queryKey: [
      'constructionProgress',
      'totalProgress',
      projectIds,
      scanDeviceIds,
      dateRange,
      Number(page),
    ],
    placeholderData: (previousData) => previousData,
    queryFn: () => {
      const constructionClient =
        new constructionProgressClientApi.ConstructionProgressServiceClient(transport);

      return constructionClient.getTotalProgress({
        projectIds,
        scanDeviceIds,
        timeRange: toTimeRange(dateRange),
        options: {
          withPaginationInfo: true,
          contextId: '', // non-optional. Needs to be set to empty string. Yes, this is not clean!
          page,
          pageSize,
        },
      }).response;
    },
  });

  return {
    ...res,
    progress: res.data?.progress ?? [],
    pageNr: Number(res.data?.paginationInfo?.page ?? 0),
    pageSize: res.data?.paginationInfo?.pageSize ?? pageSize,
    totalItems: Number(res.data?.paginationInfo?.totalItems ?? 0),
    paginate: (next: GridPaginationModel) => setPage(BigInt(next.page)),
  };
};
