import { commonApiV1 as commonApi, timestampApi } from '@deepup/apis';
import i18next from 'i18next';
import { DateTime, type DateTimeFormatOptions, Duration } from 'luxon';

import type { DateRange } from '@utils/types';

// TODO: open up for translation lib
const germanLocale: Intl.LocalesArgument = 'de-DE';

type LocalizationOptions = {
  format?: DateTimeFormatOptions;
  locale?: string;
  zone?: string;
  showZone?: boolean;
};
export const localizedDateString = (
  date: DateTime,
  {
    zone = 'cet',
    format = DateTime.DATETIME_SHORT_WITH_SECONDS,
    locale = 'de-DE',
    showZone = true,
  }: LocalizationOptions = {},
) => {
  const dateString = date.reconfigure({ locale: locale }).setZone(zone).toLocaleString(format);

  return `${dateString}${showZone && zone ? ` ${zone.toUpperCase()}` : ''}`;
};

export const localizedDuration = (
  duration: Duration,
  locale: Intl.LocalesArgument = germanLocale,
) =>
  duration
    .rescale()
    .mapUnits((x, u) => (u === 'milliseconds' ? 0 : x)) // drop milliseconds
    .rescale()
    .reconfigure({ locale: locale ? locale.toString() : undefined })
    .toHuman();

export const i18nToIntl = (language: 'en' | 'de') => {
  const map = {
    en: 'en-US',
    de: 'de-DE',
  };

  return map[language];
};

export const fromTimestamp = (date: timestampApi.Timestamp | string) => {
  if (typeof date === 'string') {
    return DateTime.fromJSDate(new Date(date));
  }

  return DateTime.fromJSDate(timestampApi.Timestamp.toDate(date));
};

export const localizeTimestampApi = (
  date: timestampApi.Timestamp | undefined | null,
  localeString?: string,
) => {
  if (date) {
    const locale = localeString || i18next.language;
    const dateTime = fromTimestamp(date);

    return localizedDateString(dateTime, { locale, format: DateTime.DATE_SHORT, showZone: false });
  }
};

export const isDateRangeValid = (dateRange: DateRange) =>
  dateRange.from?.isValid && dateRange.to?.isValid;

export const toTimestamp = (date: DateTime) => {
  if (!date.isValid) {
    throw new Error('Invalid date');
  }

  return timestampApi.Timestamp.fromDate(date.toJSDate());
};

export const removeTimezone = (dateTime: DateTime<true>) =>
  dateTime.toUTC(0, { keepLocalTime: true });

export const toTimeRange = (
  dateRange: DateRange,
  normalizeTimezone = true,
): commonApi.TimeRange => {
  if (!isDateRangeValid(dateRange)) {
    throw new Error('Invalid date range');
  }

  return commonApi.TimeRange.fromJson({
    start: (normalizeTimezone ? removeTimezone(dateRange.from!) : dateRange.from!).toISO({
      suppressMilliseconds: true,
    }),
    end: (normalizeTimezone ? removeTimezone(dateRange.to!) : dateRange.to!).toISO({
      suppressMilliseconds: true,
    }),
  });
};
