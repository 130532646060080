import { Button, Typography, Stack, Dialog, DialogContent } from '@mui/material';
import { Box, styled } from '@mui/system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

const TypographyBold = styled(Typography)({
  fontWeight: 700,
  lineHeight: 1.4,
});

export function Page() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  if (!open) {
    return <Navigate to="/map" />;
  }

  return (
    <Dialog maxWidth={'lg'} onClose={() => setOpen(false)} open={open}>
      <DialogContent>
        <Box
          alignItems="center"
          component="div"
          display="flex"
          flexDirection="column"
          position="relative"
        >
          <Stack direction="row" p={3}>
            <Box component="div" display="flex" flexDirection="column" gap={2}>
              <TypographyBold variant="h4">
                {t('pages.welcome.mainTitleTop')}
                <br />
                {t('pages.welcome.mainTitleBottom')}
              </TypographyBold>
              <TypographyBold variant="h5">{t('pages.welcome.subTitle')}</TypographyBold>
              <Typography variant="body2">{t('pages.welcome.description')}</Typography>
              <Box component="div" display="flex" justifyContent="start" pt={1}>
                <Button
                  color="primary"
                  onClick={() => setOpen(false)}
                  size="medium"
                  variant="contained"
                >
                  {t('pages.welcome.cta')}
                </Button>
              </Box>
            </Box>
            <Box component="div">
              <img alt="Page was moved" src="/welcome.png" width="380" />
            </Box>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
