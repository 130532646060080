export const cleanFileName = (fileName: string) => {
  return fileName.replace(/[^a-zA-Z0-9.]|[.](?=.*[.])/g, '_');
};

export const getFileName = (href: string) => {
  const fileName = href.split('/').pop();

  // replace all special characters and non-last dots with _
  return fileName ? cleanFileName(fileName) : 'filename';
};

export const downloadFile = (href: string, name?: string) => {
  const link = document.createElement('a');

  link.setAttribute('download', name ?? getFileName(href));
  link.href = href;
  document.body.appendChild(link);
  link.click();
  link.remove();
};
