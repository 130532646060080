import { type Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { RpcRestError } from '@deepup/api-utils';
import {
  organizationManagementClientApiV1 as organizationManagementClientApi,
  organizationManagementApiV1 as organizationManagementApi,
} from '@deepup/apis';
import { useQuery } from '@tanstack/react-query';

import { useGrpcRestTransport } from '@hooks/useGrpcRestTransport';
import { getEnvironment } from '@utils/getEnvironment';

export const useApiOrganizations = () => {
  const transport = useGrpcRestTransport();

  return useQuery<organizationManagementApi.Organizations, RpcRestError>({
    queryKey: ['organizationManagement', 'listOrganizations'],
    queryFn: () => {
      const client = new organizationManagementClientApi.OrganizationManagementServiceClient(
        transport,
      );

      return client.listOrganizations({
        name: '',
        options: { pageSize: 200, page: BigInt(0), contextId: '', withPaginationInfo: false },
      }).response;
    },
    staleTime: Infinity,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
  });
};

export const useApiProjects = (organizationId?: string | null) => {
  const transport = useGrpcRestTransport();

  return useQuery<organizationManagementApi.Projects, RpcRestError>({
    queryKey: ['organizationManagement', 'listProjects', organizationId],
    queryFn: () => {
      const client = new organizationManagementClientApi.OrganizationManagementServiceClient(
        transport,
      );

      return client.listProjects({
        organizationId: organizationId ?? '',
        name: '',
        options: { pageSize: 200, page: BigInt(0), contextId: '', withPaginationInfo: false },
      }).response;
    },
    staleTime: Infinity,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
    enabled: !!organizationId,
  });
};

// TODO: remove the following types and function, if useApiProjects has permission param and no data leak

type ProjectsDeprecatedProps = {
  organizationId: string | null;
  size?: number;
  permission?: string;
};

type ProjectDeprecated = {
  id: string;
  name: string;
  organizationId: string;
};

type ProjectsDeprecatedResponse = {
  content: ProjectDeprecated[];
};

const fetchProjects = async (
  auth: Auth0ContextInterface,
  props?: ProjectsDeprecatedProps,
): Promise<{ items: ProjectDeprecated[] }> => {
  const { apiUnified } = getEnvironment();
  const token = await auth.getAccessTokenSilently();

  const searchParams = new URLSearchParams();

  if (props?.organizationId) {
    searchParams.append('organizationId', props.organizationId);
  }

  if (props?.size !== undefined) {
    searchParams.append('size', String(props.size));
  }

  if (props?.permission) {
    searchParams.append('permission', props.permission);
  }

  const searchString = searchParams.toString();

  const response = await fetch(
    `${apiUnified}organization-management/v1/projects${searchString && `?${searchString}`}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  if (!response.ok) {
    throw new RpcRestError('No projects could be fetched', undefined, undefined, {
      message: 'No projects could be fetched',
      details: [],
      code: 0,
    });
  }

  const responseJson: ProjectsDeprecatedResponse = await response.json();

  return { items: responseJson.content };
};

export const useApiProjectsDeprecated = (props?: ProjectsDeprecatedProps) => {
  const auth0 = useAuth0();

  return useQuery<{ items: ProjectDeprecated[] }, RpcRestError>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['useApiProjectsOld', props],
    queryFn: () => fetchProjects(auth0, props),
    staleTime: Infinity,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
    enabled: !!props?.organizationId,
  });
};
