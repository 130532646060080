import { isNumber } from '@turf/helpers';
import { useFlags } from 'flagsmith/react';

const PRELABEL_ZOOM_THRESHOLD = 15;
const PHOTOS_CLUSTER_ZOOM_THRESHOLD = 15;

export const useFeatureFlags = () => {
  const {
    billing_enabled,
    trasses_enabled,
    prelabels_enabled,
    plandata_enabled,
    infobox_enabled,
    prelabel_zoom_threshold,
    photos_cluster_zoom_threshold,
    cockpit_migration_enabled,
    raw_images_enabled,
    statistics_enabled,
    segment_width_depth,
  } = useFlags([
    'billing_enabled',
    'trasses_enabled',
    'prelabels_enabled',
    'plandata_enabled',
    'infobox_enabled',
    'prelabel_zoom_threshold',
    'photos_cluster_zoom_threshold',
    'cockpit_migration_enabled',
    'raw_images_enabled',
    'statistics_enabled',
    'segment_width_depth',
  ]);

  return {
    isBillingEnabled: billing_enabled.enabled,
    isTrassesEnabled: trasses_enabled.enabled,
    isPrelabelsEnabled: prelabels_enabled.enabled,
    isPlandataEnabled: plandata_enabled.enabled,
    isInfoBoxEnabled: infobox_enabled.enabled,
    isCockpitMigrationEnabled: cockpit_migration_enabled.enabled,
    isRawImagesEnabled: raw_images_enabled.enabled,
    isStatisticsEnabled: statistics_enabled.enabled,
    isSegmentWidthDepthEnabled: segment_width_depth.enabled,
    prelabelZoomThreshold:
      prelabel_zoom_threshold.enabled && isNumber(prelabel_zoom_threshold.value)
        ? Number(prelabel_zoom_threshold.value)
        : PRELABEL_ZOOM_THRESHOLD,
    photosClusterZoomThreshold:
      photos_cluster_zoom_threshold.enabled && Number(photos_cluster_zoom_threshold.value)
        ? Number(photos_cluster_zoom_threshold.value)
        : PHOTOS_CLUSTER_ZOOM_THRESHOLD,
  };
};
