import { Box, CircularProgress, ImageList, ImageListItem, Typography } from '@mui/material';

import { type Data } from '@hooks/useApiScan';

export const ImageGrid = ({
  data,
  isLoading,
  setSelectedImageId,
  selectedImageId,
  isFullscreen,
  setFullscreen,
}: {
  data?: Data;
  isLoading: boolean;
  setSelectedImageId: (selectedImageId: string) => void;
  selectedImageId: string | null;
  isFullscreen: boolean;
  setFullscreen: () => void;
}) => {
  if (isLoading) {
    return (
      <Box
        component="div"
        sx={{
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!data) {
    return <Typography>Bilder konnten nicht geladen werden</Typography>;
  }

  const { images } = data;

  if (images.length <= 0) {
    return <Typography>Keine Bilder für diesen Scan</Typography>;
  }

  return (
    <ImageList
      cols={4}
      sx={{ width: !isFullscreen || !selectedImageId ? '100%' : '29%', padding: 1, margin: 0 }}
    >
      {images.map(({ id, downloadUrl }) => (
        <ImageListItem
          key={id}
          onClick={() => {
            setSelectedImageId(id);
            setFullscreen();
          }}
          sx={{
            aspectRatio: '1/1',
            opacity: id === selectedImageId ? 0.5 : 1,
            transition: 'opacity 0.2s',
            cursor: 'pointer',
          }}
        >
          <img alt="Raw Scan" loading="lazy" src={downloadUrl} />
        </ImageListItem>
      ))}
    </ImageList>
  );
};
