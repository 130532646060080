import { photoApiV1 as photoApi } from '@deepup/apis';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { useState, useEffect, useRef } from 'react';

import { usePhotoPackage, useCreatePackage } from '@hooks/useApiPhotoPackage';
import { localizeTimestampApi } from '@utils/timeFormatting';

import { Expiration, getLinkExpiration } from './utils';

type Props = {
  photos: photoApi.Photo[];
  project: {
    id: string;
    name: string;
  };
};

export const DownloadButton = ({ photos, project }: Props) => {
  const [isAutoDownload, setIsAutoDownload] = useState(false);

  const theme = useTheme();
  const queryClient = useQueryClient();
  const { data } = usePhotoPackage(project.id);

  const photoPackage = data?.photoPackage;
  const isZipPending = data?.isPollingActive;
  const isZipFailed = photoPackage?.status === photoApi.PhotoPackageStatus.FAILED;
  const downloadLink = data?.downloadLink;
  const expiration = getLinkExpiration(downloadLink);
  const downloadRef = useRef<HTMLAnchorElement>(null);

  const createPackageMutation = useCreatePackage();

  const handleCreateZip = () => {
    createPackageMutation.mutateAsync(
      {
        projectId: project.id,
        photoIds: photos.map((item) => item.id),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ['photoClient.listPhotoPackages', project.id],
          });
        },
      },
    );
  };

  useEffect(() => {
    if (downloadRef.current && isAutoDownload && expiration === Expiration.available) {
      downloadRef.current.click();
    }
  }, [isAutoDownload, expiration]);

  return (
    <Box component="div">
      <Paper elevation={2} sx={{ padding: theme.spacing(2) }}>
        <Stack direction="row" spacing={2}>
          <Typography
            paragraph
            sx={{ fontWeight: 'bold', paddingTop: theme.spacing(1.4) }}
            variant="subtitle1"
          >
            {t('components.photoDownload.download.title')}
          </Typography>

          {photoPackage && downloadLink ? (
            <Stack component="div" direction="column" spacing={1}>
              <Button
                disabled={expiration !== Expiration.available}
                download
                href={downloadLink.downloadUrl}
                ref={downloadRef}
                size="medium"
                sx={{
                  height: 48,
                }}
                variant="contained"
              >
                <Typography noWrap>
                  {t('components.photoDownload.download.downloadCta')} (
                  {photoPackage.photoIds.length})
                </Typography>
              </Button>
              <Typography noWrap variant="body2">
                {t('components.photoDownload.download.created')}{' '}
                {localizeTimestampApi(photoPackage.createdAt)}
                {!!photoPackage.expiresAt && (
                  <>
                    <br />
                    {expiration === Expiration.available &&
                      t('components.photoDownload.download.available')}
                    {expiration === Expiration.expired &&
                      t('components.photoDownload.download.expired')}{' '}
                    {localizeTimestampApi(photoPackage.expiresAt)}
                  </>
                )}
              </Typography>
            </Stack>
          ) : (
            <Stack component="div" direction="column" spacing={1}>
              <Typography
                p={2}
                sx={{
                  width: 200,
                  border: '2px dashed',
                  borderColor: theme.palette.grey[400],
                  color: theme.palette.grey[400],
                  borderRadius: theme.spacing(1),
                  textAlign: 'center',
                }}
                variant="body2"
              >
                {t('components.photoDownload.download.empty')}
              </Typography>
            </Stack>
          )}

          {isZipFailed && (
            <Stack component="div" direction="column" spacing={1}>
              <Typography
                p={2}
                sx={{
                  width: 200,
                  border: '2px dashed',
                  borderColor: theme.deepupColors.red[400],
                  color: theme.deepupColors.red[400],
                  borderRadius: theme.spacing(1),
                  textAlign: 'center',
                }}
                variant="body2"
              >
                {t('components.photoDownload.errors.packageNotCreated')}
              </Typography>
            </Stack>
          )}

          <Box component="div">
            <Button
              color="primary"
              disabled={isZipPending}
              onClick={handleCreateZip}
              size="medium"
              sx={{
                height: 48,
              }}
              variant="contained"
            >
              {!isZipPending ? (
                <>
                  <Typography noWrap>
                    {t('components.photoDownload.download.produceCta')} ({photos.length})
                  </Typography>
                </>
              ) : (
                <Stack alignItems="center" direction="row" spacing={2}>
                  <CircularProgress color="warning" />
                  <Typography noWrap>
                    {t('components.photoDownload.download.pendingLabel')}
                  </Typography>
                </Stack>
              )}
            </Button>
            {isZipPending && (
              <Box component="div">
                <FormControlLabel
                  control={
                    <Checkbox onChange={(event) => setIsAutoDownload(event.target.checked)} />
                  }
                  label={
                    <Typography noWrap>
                      {t('components.photoDownload.download.automatedLabel')}
                    </Typography>
                  }
                />
              </Box>
            )}
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
};
