import { photoApiV1 as photoApi } from '@deepup/apis';

import { difference } from '@utils/core';
import { fromTimestamp, localizeTimestampApi } from '@utils/timeFormatting';
import type { DateRange } from '@utils/types';

export const isDateInRange = (dateRange: DateRange) => (photo: photoApi.Photo) => {
  if (!photo.recordedAt) {
    return false;
  }
  const dateAsDateTime = fromTimestamp(photo.recordedAt).startOf('day');

  if (dateRange.to && dateAsDateTime > dateRange.to.startOf('day')) {
    return false;
  }

  return !(dateRange.from && dateAsDateTime < dateRange.from.startOf('day'));
};

export const isInTextSearch = (textSearch: string) => (photo: photoApi.Photo) => {
  return [
    photo.location?.city,
    photo.location?.place,
    photo.comments?.map((item) => item.text).join(' '),
    photo.categoryName,
    localizeTimestampApi(photo.recordedAt),
    photo.deviceId,
  ]
    .map((value) => value || '')
    .join(' ')
    .toLowerCase()
    .includes(textSearch.toLowerCase());
};

export const isInScanDevices =
  (scanDevices: string[], availableDevices: string[]) => (photo: photoApi.Photo) => {
    if (scanDevices.length === 0) {
      return true; // all selected
    }
    if (scanDevices.length === availableDevices.length) {
      return false; // all unselected
    }
    const selectedItems = difference(scanDevices, availableDevices);

    return selectedItems.includes(photo.deviceId);
  };

export const isInCategories =
  (photoCategories: string[], availableCategories: string[]) => (photo: photoApi.Photo) => {
    if (photoCategories.length === 0) {
      return true; // all selected
    }
    if (photoCategories.length === availableCategories.length) {
      return false; // all unselected
    }

    const selectedItems = difference(photoCategories, availableCategories);

    return selectedItems.includes(photo.categoryName);
  };

export const getPhotoFilename = (photo: photoApi.Photo) => {
  const parts = [
    photo.location?.place.replace(/\W+/g, '_'),
    photo.location?.city.replace(/\W+/g, '_'),
    photo.recordedAt
      ? fromTimestamp(photo.recordedAt).setZone('Europe/Berlin').toFormat('yyyy-MM-dd_HH-mm')
      : undefined,
    photo.categoryName.replace(/\W+/g, '_'),
  ];

  return parts.filter(Boolean).join('_');
};
