import { Box, Typography, Link as MuiLink } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const ErrorPage = ({
  errorText,
  statusCode = 404,
}: {
  errorText: string;
  statusCode?: number;
}) => {
  const { t } = useTranslation();

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: '#00A887',
        gap: '20px',
        flex: 1,
      }}
    >
      <Typography color="text.primary" variant="h1">
        {statusCode}
      </Typography>
      <Typography color="text.primary" variant="h6">
        {errorText}
      </Typography>

      <MuiLink color="text.primary" component={Link} to="/" variant="body1">
        {t('components.errorPage.homePageLink')}
      </MuiLink>
    </Box>
  );
};
