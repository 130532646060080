import { RpcRestError } from '@deepup/api-utils';
import { scanApiV1 as scanApi, scanClientApiV1 as scanClientApi } from '@deepup/apis';
import { useQuery } from '@tanstack/react-query';

import { useGrpcRestTransport } from '@hooks/useGrpcRestTransport';

export type Data = scanApi.ScanImages;

export const useListScanImages = ({
  scanId,
  resolution = scanApi.ScanImageResolution.HIGH,
}: {
  scanId?: string;
  resolution?: scanApi.ScanImageResolution;
}) => {
  const transport = useGrpcRestTransport();

  return useQuery<scanApi.ScanImages, RpcRestError>({
    queryKey: ['scanApi', 'listScanImages', { scanId }, resolution],
    queryFn: async () => {
      if (!scanId) {
        throw new RpcRestError('Scan ID is missing', 'NO_SCAN_ID', undefined, {
          code: 0,
          message: 'Scan ID is missing',
          details: [],
        });
      }

      const client = new scanClientApi.ScanServiceClient(transport);
      const imageResponse = await client.listScanImages({
        scanId,
        resolution,
      }).response;

      const sortedImages = imageResponse.images.sort((imgA, imgB) => {
        if (!(imgA.createdAt && imgB.createdAt)) {
          return -1;
        }

        return imgA.createdAt.nanos - imgB.createdAt.nanos;
      });

      return { images: sortedImages };
    },
    staleTime: Infinity,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
    enabled: !!scanId,
  });
};
