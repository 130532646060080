import type { commentApiV1 as commentApi } from '@deepup/apis';
import { EditNote } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { useId, useState, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, type AccordionControlProps } from '@components/Accordion';
import { CommentForm, PostComment } from '@components/PhotoDownload/PostComment';
import { Table, TableCell, TableCellText, TableRow } from '@components/Table';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import type { PhotoInfo } from '@hooks/useFetchPhotoInfo';

export type PhotoInfoCardProps = AccordionControlProps & {
  info?: PhotoInfo & { comments?: commentApi.Comment[]; commentsDevice?: commentApi.Comment[] };
  children?: ReactNode;
};

export const PhotoInfoCard = ({ expanded, setExpanded, collapsable, info }: PhotoInfoCardProps) => {
  const { t } = useTranslation();
  const [editCommentId, setEditCommentId] = useState<string | null>(null);
  const { isCockpitMigrationEnabled } = useFeatureFlags();
  const commentKeyId = useId();
  const commentDeviceKeyId = useId();

  return (
    <Accordion
      collapsable={collapsable}
      expanded={expanded}
      setExpanded={setExpanded}
      title={t('components.photoInfoCard.title')}
    >
      {!info && (
        <Table>
          <TableRow>
            <TableCellText showBorder={!collapsable}>
              {t('components.photoInfoCard.info.noData')}
            </TableCellText>
            <TableCellText showBorder={!collapsable}></TableCellText>
          </TableRow>
        </Table>
      )}
      {info && (
        <Table>
          <TableRow>
            <TableCellText>{t('components.photoInfoCard.info.id')}</TableCellText>
            <TableCellText>{info.id}</TableCellText>
          </TableRow>
          {info.address && (
            <TableRow>
              <TableCellText>{t('components.photoInfoCard.info.address')}</TableCellText>
              <TableCellText>{info.address}</TableCellText>
            </TableRow>
          )}
          {info.category && (
            <TableRow>
              <TableCellText>{t('components.photoInfoCard.info.category')}</TableCellText>
              <TableCellText>{info.category}</TableCellText>
            </TableRow>
          )}
          <TableRow>
            <TableCellText>{t('components.photoInfoCard.info.date')}</TableCellText>
            <TableCellText>{info.date}</TableCellText>
          </TableRow>
          <TableRow>
            <TableCellText>{t('components.photoInfoCard.info.precision')}</TableCellText>
            <TableCellText>{info.solution}</TableCellText>
          </TableRow>
          <TableRow>
            <TableCellText showBorder={!!info.text || !collapsable}>
              {t('components.photoInfoCard.info.device')}
            </TableCellText>
            <TableCellText showBorder={!!info.text || !collapsable}>{info.device}</TableCellText>
          </TableRow>
          {info.text && (
            <TableRow>
              <TableCellText showBorder={!collapsable}>
                {t('components.photoInfoCard.info.comment')}
              </TableCellText>
              <TableCellText showBorder={!collapsable}>{info.text}</TableCellText>
            </TableRow>
          )}
          {!!info.commentsDevice?.length && (
            <TableRow>
              <TableCellText>{t('components.photoDownload.table.commentDevice')}</TableCellText>
              <TableCell>
                {info.commentsDevice.map((comment, i) => {
                  return (
                    <Typography key={`${commentDeviceKeyId}-${i}`} variant="body2">
                      {comment.text}
                    </Typography>
                  );
                })}
              </TableCell>
            </TableRow>
          )}
          {isCockpitMigrationEnabled && info.comments && (
            <TableRow>
              <TableCellText showBorder={!collapsable}>
                {t('components.photoInfoCard.info.comment')}
              </TableCellText>
              <TableCell showBorder={!collapsable}>
                {info.comments.length ? (
                  <Stack component="div" spacing={2}>
                    {info.comments.map((comment, i) => {
                      if (editCommentId !== null && comment.id === editCommentId) {
                        return (
                          <CommentForm
                            initialText={comment.text}
                            key={comment.id}
                            onClose={() => setEditCommentId(null)}
                            photoId={info.id}
                          />
                        );
                      }

                      return (
                        <Stack
                          alignItems="center"
                          component="div"
                          direction="row"
                          key={`${commentKeyId}-${i}`}
                          spacing={1}
                        >
                          {comment.text}
                          <IconButton onClick={() => setEditCommentId(comment.id)}>
                            <EditNote />
                          </IconButton>
                        </Stack>
                      );
                    })}
                  </Stack>
                ) : (
                  <PostComment photoId={info.id} />
                )}
              </TableCell>
            </TableRow>
          )}
        </Table>
      )}
    </Accordion>
  );
};
