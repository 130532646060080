import { Stack } from '@mui/material';
import { useState } from 'react';

import { ImageCarousel } from '@components/ImageCarousel';
import { ImageZoom } from '@components/ImageZoom';
import { useListScanImages } from '@hooks/useApiScan';

import { ImageGrid } from './ImageGrid';

export const RawImageViewer = ({
  scanId,
  isFullscreen,
  setFullscreen,
}: {
  scanId?: string;
  isFullscreen: boolean;
  setFullscreen: () => void;
}) => {
  const [selectedImageId, setSelectedImageId] = useState<null | string>(null);

  const { data, isLoading } = useListScanImages({ scanId });

  const images = data?.images ?? [];
  const selectedImage = images.find((img) => img.id === selectedImageId);

  return (
    <Stack direction="row" sx={{ height: '100%' }}>
      {selectedImage && isFullscreen && (
        <ImageCarousel
          index={images.indexOf(selectedImage)}
          label={'Raw Images'}
          onSlideChange={(index) => {
            const slideImage = images.at(index);

            if (slideImage) {
              setSelectedImageId(slideImage.id);
            }
          }}
          slides={images.map((img) => (
            <ImageZoom
              image={
                <img
                  alt=""
                  key={img.id}
                  loading="lazy"
                  src={img.downloadUrl}
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'block',
                  }}
                />
              }
              key={img.id}
            />
          ))}
          sx={{ height: '100%', width: '100%' }}
        />
      )}
      <ImageGrid
        data={data}
        isFullscreen={isFullscreen}
        isLoading={isLoading}
        selectedImageId={selectedImageId}
        setFullscreen={setFullscreen}
        setSelectedImageId={setSelectedImageId}
      />
    </Stack>
  );
};
