import { Stack, TextField, Typography } from '@mui/material';
import { DataGrid, type GridColDef } from '@mui/x-data-grid';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import { useState, useEffect } from 'react';

import { TextLink } from '@components/TextLink';
import { useApiProjects } from '@hooks/useApiProjects';
import { getEnvironment } from '@utils/getEnvironment';

const { cssUrl } = getEnvironment();

export function Page() {
  const [searchQuery, setSearchQuery] = useState('');
  const { data, isLoading, error } = useApiProjects();

  useEffect(() => {
    if (error) {
      enqueueSnackbar(t('pages.map.project.error.api'), { variant: 'error' });
    }
  }, [error]);

  const allProjects = data?.data || [];

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('pages.map.project.table.name'),
      width: 150,
      flex: 1,
      filterable: false,
      renderCell: (params) => (
        <TextLink
          href={`${cssUrl}projects/${params.row.id}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {params.row.name}
        </TextLink>
      ),
    },
  ];

  const filteredProjects = allProjects.filter((project) => {
    const searchQueryLower = searchQuery.toLowerCase();
    const projectNameLower = project.name.toLowerCase();

    return projectNameLower.includes(searchQueryLower);
  });

  return (
    <Stack
      direction="column"
      sx={(theme) => ({
        margin: '2rem auto',
        width: '90%',
        [theme.breakpoints.up('md')]: {
          maxWidth: '50%',
        },
      })}
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between" mb={2}>
        <Typography fontWeight="bold" gutterBottom variant="h6">
          {t('pages.map.project.title')}
        </Typography>
        <TextField
          label={t('pages.map.project.search.label')}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={(theme) => ({
            width: theme.spacing(32),
          })}
          value={searchQuery}
        />
      </Stack>

      <DataGrid
        columns={columns}
        disableColumnMenu
        disableRowSelectionOnClick
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
        loading={isLoading}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        rows={filteredProjects}
      />
    </Stack>
  );
}
