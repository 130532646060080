import { constructionProgressApiV1 as constructionProgressApi } from '@deepup/apis';
import { Stack, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import type { BarChartProps } from '@mui/x-charts';
import { BarChart } from '@mui/x-charts';
import { listEnumValues } from '@protobuf-ts/runtime';
import type { DateTimeFormatOptions } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useApiTimeSeries } from '@hooks/useApiConstructionProgress';
import { useFilters } from '@hooks/useFilters';
import { mapSum } from '@utils/core';
import { fromTimestamp } from '@utils/timeFormatting';

import { ProgressTableClient } from './ProgressTable';

const useChartSeries = () => {
  const { t } = useTranslation();

  const trenches: BarChartProps['series'] = [
    {
      dataKey: 'prelabeledMeters',
      stack: 'trench m',
      label: t('pages.statistics.constructionProgress.progressChart.aiMeters'),
      valueFormatter: (val = 0) => t('decimal', { val }),
    },
    {
      dataKey: 'labeledMeters',
      stack: 'trench m',
      label: t('pages.statistics.constructionProgress.progressChart.qualityMeters'),
      valueFormatter: (val = 0) => t('decimal', { val }),
    },
  ];
  const houseLeads: BarChartProps['series'] = [
    {
      dataKey: 'numberOfHouseLeads',
      stack: 'house leads',
      label: t('pages.statistics.constructionProgress.progressChart.nrHouseLeads'),
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ({ trenches, houseLeads }), [t]);
};

const Chart = ({
  dataset,
  colors,
  loading,
  onSelectSeries,
  series,
}: {
  onSelectSeries: (index: number) => void;
} & Pick<BarChartProps, 'series' | 'dataset' | 'colors' | 'loading'>) => (
  <BarChart
    colors={colors}
    dataset={dataset}
    grid={{ horizontal: true }}
    loading={loading}
    onItemClick={(_, item) => {
      onSelectSeries(item.dataIndex);
    }}
    series={series}
    xAxis={[
      {
        scaleType: 'band',
        dataKey: 'date',
      },
    ]}
  />
);

const intervals = listEnumValues(constructionProgressApi.Interval);

const dateFormats: Record<number, DateTimeFormatOptions> = {
  [constructionProgressApi.Interval.DAILY]: { month: '2-digit', day: '2-digit' },
  [constructionProgressApi.Interval.WEEKLY]: { month: '2-digit', day: '2-digit' },
  [constructionProgressApi.Interval.MONTHLY]: { month: '2-digit' },
};

export const Charts = () => {
  const theme = useTheme();
  const { interval, setInterval, scanDevices, projectList, dateRange } = useFilters();

  const { items, isFetching, hasNextPage, fetchNextPage } = useApiTimeSeries(
    projectList,
    scanDevices,
    dateRange,
    interval,
  );

  useEffect(() => {
    if (hasNextPage && !isFetching) fetchNextPage();
  }, [hasNextPage, isFetching, fetchNextPage, items]);

  const [selectedSeries, setSelectedSeries] = useState<number>();
  const { t } = useTranslation();

  const dataset = items.map(({ startsAt, progress, totalMeters }) => ({
    date: fromTimestamp(startsAt!).toLocaleString(dateFormats[interval]),
    totalMeters,
    numberOfHouseLeads: mapSum(progress, (p) => p.numberOfHouseLeads),
    prelabeledMeters: mapSum(progress, (p) => p.prelabeledMeters),
    labeledMeters: mapSum(progress, (p) => p.labeledMeters),
  }));

  const series = useChartSeries();

  const isStillFetchingPages = isFetching || hasNextPage;

  return (
    <>
      <Stack alignItems="center">
        <ToggleButtonGroup size="small" value={interval}>
          {intervals.map(({ name, number }) => (
            <ToggleButton
              key={name}
              onClick={(_, nextInterval) => setInterval(nextInterval)}
              value={number}
            >
              {t(`pages.statistics.${name}` as never)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Stack>
      <Stack direction="row" height={350}>
        <Chart
          colors={[theme.palette.primary.dark, theme.palette.primary.main]}
          dataset={dataset}
          loading={isStillFetchingPages}
          onSelectSeries={setSelectedSeries}
          series={series.trenches}
        />
        <Chart
          colors={[theme.palette.warning.main]}
          dataset={dataset}
          loading={isStillFetchingPages}
          onSelectSeries={setSelectedSeries}
          series={series.houseLeads}
        />
      </Stack>
      {selectedSeries !== undefined && items[selectedSeries] && (
        <ProgressTableClient progress={items[selectedSeries].progress} />
      )}
    </>
  );
};
