import type { MapboxGeoJSONFeature } from 'mapbox-gl';
import { useMemo } from 'react';

import { type Item, namesToItems } from '@components/FilterBox/fields/MultiSelect';
import type { FilterStates } from '@hooks/useFilters';
import { distinct } from '@utils/core';

export const useScanDevicesFromFeatures = (
  features: MapboxGeoJSONFeature[],
  scanDeviceNames: FilterStates['scanDevices'],
): Item[] =>
  useMemo(
    () =>
      namesToItems(
        distinct([
          // add devices from get param to the list
          ...scanDeviceNames,
          ...features
            .filter(
              (feature) => feature.properties?.scan_device_name || feature.properties?.scan_device,
            )
            .map(
              (feature) => feature.properties?.scan_device_name ?? feature.properties?.scan_device,
            ),
        ]).sort(Intl.Collator().compare),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [features, JSON.stringify(scanDeviceNames)],
  );
