/**
 * ATTENTION: you shouldn't ideally use this function EVER! It is a dirty workaround after all!
 *
 * Delay code execution for a given amount of time asynchronously.
 * You can use this to delay a function which has a side effect like reading / writing from/to an external source, which might need some ms to be ready but has
 * no event to subscribe to (e.g. some MapBox events like animation).
 *
 * @param ms - The amount of time to wait in milliseconds.
 */
export const wait = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));
