export const SearchNotPossible = () => {
  return (
    <svg
      fill="none"
      height="240"
      viewBox="0 0 240 240"
      width="240"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="120" cy="120" fill="#E0E0E0" r="120" />
      <rect fill="#F5F5F5" height="127.727" rx="1.7947" width="158.636" x="40.6816" y="56.1364" />
      <path
        d="M40.6816 57.931C40.6816 56.9399 41.4852 56.1364 42.4763 56.1364H197.523C198.514 56.1364 199.318 56.9399 199.318 57.931V72.2727H40.6816V57.931Z"
        fill="#757575"
      />
      <circle cx="48.6365" cy="65" fill="#D9D9D9" r="2.27273" />
      <circle cx="57.0457" cy="65" fill="#D9D9D9" r="2.27273" />
      <ellipse
        cx="120.123"
        cy="121.7"
        fill="#1A1A1A"
        fillOpacity="0.2"
        rx="38.1256"
        ry="31.6799"
        transform="rotate(-54.6271 120.123 121.7)"
      />
      <circle cx="65.4544" cy="65" fill="#D9D9D9" r="2.27273" />
      <path
        d="M156.888 153.141L149.317 161.124L183.12 193.182L190.691 185.199L156.888 153.141Z"
        fill="#212121"
      />
      <path d="M153.221 157.385L146.708 151.317" stroke="#424242" strokeWidth="10.1605" />
      <path
        d="M120.197 83.1819C141.503 83.1819 158.802 100.539 158.802 121.917C158.802 143.295 141.503 160.652 120.197 160.652C98.8901 160.652 81.5908 143.295 81.5908 121.917C81.5908 100.539 98.8901 83.1819 120.197 83.1819Z"
        stroke="#424242"
        strokeWidth="6.46603"
      />
      <path
        d="M116.871 133.734C116.138 134.438 115.771 135.337 115.771 136.434C115.771 137.514 116.13 138.405 116.848 139.109C117.581 139.813 118.544 140.166 119.734 140.166C120.925 140.166 121.88 139.813 122.597 139.109C123.331 138.405 123.698 137.514 123.698 136.434C123.698 135.337 123.323 134.438 122.573 133.734C121.839 133.014 120.893 132.653 119.734 132.653C118.576 132.653 117.621 133.014 116.871 133.734ZM117.312 123.423C116.758 124.781 116.48 126.606 116.48 128.897H122.743L122.842 127.4C123.021 125.797 123.73 124.396 124.97 123.202L126.953 121.312C128.503 119.806 129.586 118.44 130.206 117.212C130.826 115.968 131.136 114.65 131.136 113.26C131.136 110.199 130.181 107.833 128.273 106.165C126.365 104.479 123.682 103.636 120.223 103.636C116.798 103.636 114.091 104.521 112.101 106.288C110.127 108.056 109.123 110.501 109.091 113.628H116.187C116.219 112.318 116.595 111.296 117.312 110.56C118.046 109.807 119.017 109.431 120.223 109.431C122.768 109.431 124.04 110.813 124.04 113.578C124.04 114.495 123.796 115.371 123.306 116.206C122.817 117.023 121.83 118.097 120.346 119.422C118.877 120.731 117.867 122.064 117.312 123.423Z"
        fill="white"
      />
    </svg>
  );
};
