import { Stack } from '@mui/material';
import { DatePicker as MuiDatePicker, type PickersTimezone } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { type Dispatch, type FC, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import type { DateRange } from '@utils/types';

type DatePickerProps = {
  label: string;
  value: DateTime | null;
  onChange: (date: DateTime | null) => void;
  maxDate?: DateTime;
  minDate?: DateTime;
  disabled?: boolean;
  timezone?: PickersTimezone;
};

export type DateRangePickerProps = {
  dateRange: DateRange;
  setDateRange: Dispatch<SetStateAction<DateRange>>;
  minDate?: DateTime;
  maxDate?: DateTime;
  dateProp?: string;
  disabled?: boolean;
  timezone?: PickersTimezone;
};

const DatePicker: FC<DatePickerProps> = ({
  label,
  value,
  onChange,
  minDate,
  maxDate = DateTime.now(),
  disabled,
  timezone,
}) => (
  <MuiDatePicker
    disabled={disabled}
    format="dd.MM.yyyy"
    label={label}
    maxDate={maxDate}
    minDate={minDate}
    onChange={onChange}
    slotProps={{
      actionBar: { actions: ['today', 'clear', 'cancel'] },
      textField: { variant: 'outlined', sx: { flex: '1' } },
    }}
    timezone={timezone}
    value={value}
  />
);

export const DateRangePicker: FC<DateRangePickerProps> = ({
  dateRange,
  setDateRange,
  disabled,
  minDate,
  maxDate,
  timezone,
}) => {
  const { t } = useTranslation();

  const onChangeFrom = (date: DateTime | null) => {
    setDateRange((prevState) => ({ ...prevState, from: date }));
  };

  const onChangeTo = (date: DateTime | null) => {
    setDateRange((prevState) => {
      if (prevState.from && date && date < prevState.from) {
        return { from: date, to: date };
      }

      return { ...prevState, to: date };
    });
  };

  return (
    <Stack direction="row" spacing={1} sx={{ width: 1 }}>
      <DatePicker
        disabled={disabled}
        label={t('components.filterBox.fields.dateRangePicker.fromLabel')}
        maxDate={dateRange.to ?? DateTime.now()}
        minDate={minDate}
        onChange={onChangeFrom}
        timezone={timezone}
        value={dateRange.from}
      />
      <DatePicker
        disabled={disabled}
        label={t('components.filterBox.fields.dateRangePicker.toLabel')}
        maxDate={maxDate}
        minDate={minDate}
        onChange={onChangeTo}
        timezone={timezone}
        value={dateRange.to}
      />
    </Stack>
  );
};
