import { Box, Paper, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { LoadingSpinner } from '@components/ScanInfoCard';
import { useApiDuctMetadata } from '@hooks/useApiDuctMetadata';
import { useApiTrassMetadata } from '@hooks/useApiTrassMetadata';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { useLocalizedFormat } from '@hooks/useLocalizedFormat';
import { toElements } from '@utils/elementMapping';
import {
  toSegment,
  type LayingType,
  type SurfaceType,
  type UsageType,
} from '@utils/segmentMapping';
import { localizedDateString } from '@utils/timeFormatting';

export const useUsageTypeReadable = () => {
  const { t } = useTranslation();

  const usageTypeReadable: Record<UsageType, string> = {
    HOUSE_LEAD: t('components.segmentInfoCard.readableTypes.usage.HOUSE_LEAD'),
    TRASS: t('components.segmentInfoCard.readableTypes.usage.TRASS'),
  };

  return { usageTypeReadable };
};

export const useLayingTypeReadable = () => {
  const { t } = useTranslation();

  const layingTypeReadable: Record<LayingType, string> = {
    HORIZONTAL_DIRECTIONAL_DRILLING: t(
      'components.segmentInfoCard.readableTypes.laying.HORIZONTAL_DIRECTIONAL_DRILLING',
    ),
    LAYJET: t('components.segmentInfoCard.readableTypes.laying.LAYJET'),
    MICRO_TRENCHING: t('components.segmentInfoCard.readableTypes.laying.MICRO_TRENCHING'),
    OPEN_CONSTRUCTION: t('components.segmentInfoCard.readableTypes.laying.OPEN_CONSTRUCTION'),
    PLOUGH: t('components.segmentInfoCard.readableTypes.laying.PLOUGH'),
    PULL: t('components.segmentInfoCard.readableTypes.laying.PULL'),
    SOIL_DISPLACEMENT: t('components.segmentInfoCard.readableTypes.laying.SOIL_DISPLACEMENT'),
  };

  return { layingTypeReadable };
};
export const useSurfaceTypeReadable = () => {
  const { t } = useTranslation();

  const surfaceTypeReadable: Record<SurfaceType, string> = {
    ASPHALT: t('components.segmentInfoCard.readableTypes.surface.ASPHALT'),
    HIGH_QUALITY_BRICK: t('components.segmentInfoCard.readableTypes.surface.HIGH_QUALITY_BRICK'),
    CONCRETE: t('components.segmentInfoCard.readableTypes.surface.CONCRETE'),
    MISSING: t('components.segmentInfoCard.readableTypes.surface.MISSING'),
    SIMPLE_BRICK: t('components.segmentInfoCard.readableTypes.surface.SIMPLE_BRICK'),
    UNPAVED_GRASS: t('components.segmentInfoCard.readableTypes.surface.UNPAVED_GRASS'),
  };

  return { surfaceTypeReadable };
};

export const SegmentInfoCard = ({ segmentId }: { segmentId: string }) => {
  const { isSegmentWidthDepthEnabled } = useFeatureFlags();
  const { t, i18n } = useTranslation();
  const { formatAsUnit } = useLocalizedFormat();
  const { usageTypeReadable } = useUsageTypeReadable();
  const { layingTypeReadable } = useLayingTypeReadable();
  const { surfaceTypeReadable } = useSurfaceTypeReadable();

  const { data: dataTrass, isLoading: isLoadingTrass } = useApiTrassMetadata({
    featureId: segmentId,
  });

  const ductIds = dataTrass ? dataTrass.features?.[0]?.properties?.['duct_fids']?.split(',') : null;

  const { data: dataDuct, isLoading: isLoadingDuct } = useApiDuctMetadata(
    Array.isArray(ductIds) && ductIds[0] !== '' ? { featureIds: ductIds } : null,
  );

  if (isLoadingTrass || isLoadingDuct) {
    return <LoadingSpinner />;
  }

  const segmentInfo = dataTrass?.features?.[0] ? toSegment(dataTrass.features[0]) : null;
  const elements = dataDuct ? toElements(dataDuct) : [];

  return (
    <Box component="div" sx={(theme) => ({ padding: theme.spacing(2) })}>
      {elements.length > 0 && (
        <Paper sx={(theme) => ({ padding: theme.spacing(2), marginBottom: theme.spacing(1) })}>
          <Typography variant="subtitle2">
            {t('components.segmentInfoCard.infos.elements')}:
          </Typography>
          {elements.map(
            (element) =>
              element.length && (
                <Typography key={element.id} variant="body2">
                  {element.name}{' '}
                  {element?.description && element?.description !== element.name ? (
                    <>/ {element.description} </>
                  ) : null}
                  ({formatAsUnit(element.length, 'meter')})
                </Typography>
              ),
          )}
        </Paper>
      )}
      {!segmentInfo && <p>{t('common.noDataAvailable')}</p>}
      {segmentInfo && (
        <Table size="small">
          <TableBody
            sx={{
              '& td': { border: 0 },
              '& td:nth-of-type(1)': { width: '120px' },
            }}
          >
            <TableRow>
              <TableCell>{t('components.segmentInfoCard.infos.date')}:</TableCell>
              <TableCell>
                {segmentInfo.date
                  ? localizedDateString(segmentInfo.date, {
                      format: DateTime.DATE_SHORT,
                      showZone: false,
                      locale: i18n.language,
                    })
                  : t('common.unknown')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('components.segmentInfoCard.infos.usage')}:</TableCell>
              <TableCell>{usageTypeReadable[segmentInfo.usage] ?? t('common.unknown')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('components.segmentInfoCard.infos.laying')}:</TableCell>
              <TableCell>
                {segmentInfo.laying ? layingTypeReadable[segmentInfo.laying] : t('common.unknown')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('components.segmentInfoCard.infos.surfaceType')}:</TableCell>
              <TableCell>
                {segmentInfo.surfaceType
                  ? surfaceTypeReadable[segmentInfo.surfaceType]
                  : t('common.unknown')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('components.segmentInfoCard.infos.scanDevice')}:</TableCell>
              <TableCell>{segmentInfo.scanDevice ?? t('common.unknown')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('components.segmentInfoCard.infos.length')}:</TableCell>
              <TableCell>{formatAsUnit(segmentInfo.length, 'meter')}</TableCell>
            </TableRow>
            {isSegmentWidthDepthEnabled && (
              <>
                <TableRow>
                  <TableCell>{t('components.segmentInfoCard.infos.width')}:</TableCell>
                  <TableCell>
                    {segmentInfo.width
                      ? formatAsUnit(segmentInfo.width, 'centimeter')
                      : t('components.segmentInfoCard.infos.noData')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('components.segmentInfoCard.infos.depth')}:</TableCell>
                  <TableCell>
                    {segmentInfo.depth
                      ? formatAsUnit(segmentInfo.depth, 'centimeter')
                      : t('components.segmentInfoCard.infos.noData')}
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};
