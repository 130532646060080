import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { TabsRouter } from '@components/TabsRouter';

export const Layout = () => {
  const { t } = useTranslation();

  return (
    <Stack mt={2} p={2} spacing={4} sx={{ flex: 1 }}>
      <TabsRouter
        items={[
          {
            label: t('pages.statistics.constructionProgress.title'),
            to: '/statistics/construction-progress',
          },
          {
            label: t('pages.statistics.labelingProgress.title'),
            to: '/statistics/labeling-progress',
          },
        ]}
      />
      <Outlet />
    </Stack>
  );
};
