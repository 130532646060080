import { useFeatureFlags } from '@hooks/useFeatureFlags';

export const useConfig = () => {
  const { isCockpitMigrationEnabled, isStatisticsEnabled } = useFeatureFlags();

  return {
    drawerStyle:
      isCockpitMigrationEnabled || isStatisticsEnabled
        ? {
            top: { xs: 'calc(56px + 48px)', sm: 'calc(80px + 64px)' },
            height: { xs: 'calc(100% - 56px - 48px)', sm: 'calc(100% - 80px - 64px)' },
          }
        : {
            top: { xs: '56px', sm: '80px' },
            height: { xs: 'calc(100% - 56px)', sm: 'calc(100% - 80px)' },
          },
    layoutContainerStyle: {
      paddingTop:
        isCockpitMigrationEnabled || isStatisticsEnabled
          ? { xs: 'calc(56px + 48px)', sm: 'calc(80px + 64px)' }
          : { xs: '56px', sm: '80px' },
    },
  };
};
