import { constructionProgressApiV1 as constructionProgressApi } from '@deepup/apis';
import { DataGrid, type GridColDef, type GridColumnGroupingModel } from '@mui/x-data-grid';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useApiTotalProgress } from '@hooks/useApiConstructionProgress';
import { useFilters } from '@hooks/useFilters';

type Progress = constructionProgressApi.Progress;

const useTableDefs = () => {
  const { t } = useTranslation();

  const groupingModel: GridColumnGroupingModel = [
    {
      groupId: t('pages.statistics.constructionProgress.progressTable.metersGroup.title'),
      description: t('pages.statistics.constructionProgress.progressTable.metersGroup.info'),
      children: [{ field: 'prelabeledMeters' }, { field: 'labeledMeters' }],
    },
  ];

  const colDefs: GridColDef<constructionProgressApi.Progress>[] = [
    {
      field: 'name',
      valueGetter: (_, row) => row.project?.name,
      headerName: t('pages.statistics.constructionProgress.progressTable.name'),
      flex: 1,
    },
    {
      field: 'scanDevices',
      valueGetter: (_, row) => row.scanDevices?.map((s) => s.serialNumber).join(', '),
      headerName: t('pages.statistics.constructionProgress.progressTable.scanners'),
      flex: 1,
    },
    {
      field: 'numberOfHouseLeads',
      headerName: t('pages.statistics.constructionProgress.progressTable.nrHouseLeads'),
      flex: 1,
    },
    {
      field: 'prelabeledMeters',
      headerName: t('pages.statistics.constructionProgress.progressTable.aiPrediction'),
      valueFormatter: (val) => t('decimal', { val }),
      flex: 1,
    },
    {
      field: 'labeledMeters',
      headerName: t('pages.statistics.constructionProgress.progressTable.qualityAssured'),
      valueFormatter: (val) => t('decimal', { val }),
      flex: 1,
    },
    {
      field: 'trenchMeters',
      headerName: t('pages.statistics.constructionProgress.progressTable.total'),
      valueFormatter: (val) => t('decimal', { val }),
      flex: 1,
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ({ colDefs, groupingModel }), [t]);
};

export const ProgressTableClient = ({
  progress,
  isFetching = false,
}: {
  progress: Progress[];
  isFetching?: boolean;
}) => {
  const { colDefs, groupingModel } = useTableDefs();

  return (
    <DataGrid
      columnGroupingModel={groupingModel}
      columns={colDefs}
      getRowId={(row) => row.project!.id}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 12 },
        },
      }}
      loading={isFetching}
      pageSizeOptions={[12, 24, 48]}
      pagination
      paginationMode="client"
      rows={progress}
      sx={{ height: 200 }}
    />
  );
};

export const ProgressTableServer = () => {
  const { dateRange, projectList, scanDevices } = useFilters();
  const { progress, totalItems, pageSize, pageNr, paginate, isFetching, error } =
    useApiTotalProgress(projectList, scanDevices, dateRange);
  const { colDefs, groupingModel } = useTableDefs();
  const { t } = useTranslation();

  useEffect(() => {
    if (!error) return;
    enqueueSnackbar(
      t('pages.statistics.constructionProgress.fetchingError', {
        error: error.body?.message,
      }),
      { variant: 'error' },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <DataGrid
      columnGroupingModel={groupingModel}
      columns={colDefs}
      getRowId={(row) => row.project!.id}
      loading={isFetching}
      onPaginationModelChange={paginate}
      pageSizeOptions={[pageSize]}
      pagination
      paginationMode="server"
      paginationModel={{ page: pageNr, pageSize }}
      rowCount={totalItems}
      rowSelection={false}
      rows={progress}
      sx={{ height: 200 }}
    />
  );
};
