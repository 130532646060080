import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DateTime } from 'luxon';
import { type Dispatch, type MouseEvent, type SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { DateRange } from '@utils/types';

type Items = 'today' | 'yesterday' | 'lastWeek' | 'lastMonth' | 'currentMonth';

export type DateButtonsProps = {
  dateRange: DateRange;
  setDateRange: Dispatch<SetStateAction<DateRange>>;
  disabled?: boolean;
  items?: Items[];
};

export const DateButtons = ({
  dateRange,
  setDateRange,
  disabled,
  items = ['today', 'yesterday', 'lastWeek'],
}: DateButtonsProps) => {
  const { t } = useTranslation();

  const quickFilters: Record<Items, { from: DateTime; to: DateTime; label: string; flex: number }> =
    useMemo(() => {
      const now = DateTime.now();

      return {
        today: {
          from: now.startOf('day'),
          to: now.endOf('day').set({ millisecond: 0 }),
          label: t('components.filterBox.fields.dateButtons.today'),
          flex: 1,
        },
        yesterday: {
          from: now.minus({ days: 1 }).startOf('day'),
          to: now.minus({ days: 1 }).endOf('day').set({ millisecond: 0 }),
          label: t('components.filterBox.fields.dateButtons.yesterday'),
          flex: 1,
        },
        lastWeek: {
          from: now.minus({ days: 7 }).startOf('day'),
          to: now.endOf('day').set({ millisecond: 0 }),
          label: t('components.filterBox.fields.dateButtons.7Days'),
          flex: 2,
        },
        lastMonth: {
          from: now.minus({ months: 1 }).startOf('month'),
          to: now.minus({ months: 1 }).endOf('month').set({ millisecond: 0 }),
          label: t('components.filterBox.fields.dateButtons.lastMonth'),
          flex: 2,
        },
        currentMonth: {
          from: now.startOf('month'),
          to: now.minus({ days: 1 }).endOf('day').set({ millisecond: 0 }),
          label: t('components.filterBox.fields.dateButtons.currentMonth'),
          flex: 2,
        },
      };
    }, [t]);

  const onChange = (_event: MouseEvent, value: Items) => {
    if (!value) {
      setDateRange({ from: null, to: null });

      return;
    }

    const quickFilter = quickFilters[value];

    if (quickFilter) {
      setDateRange({ from: quickFilter.from, to: quickFilter.to });
    }
  };

  // find the selected value from given dateRange
  const value = useMemo(
    () =>
      Object.keys(quickFilters).find((quickFilter) => {
        // every quickfilter has a from date
        if (!dateRange.from) return false;

        // 'today' and 'last week' can have empty 'to' dates
        const to = dateRange.to ?? DateTime.now();

        return (
          quickFilters[quickFilter as Items].from.equals(
            dateRange.from.startOf('day').set({ millisecond: 0 }),
          ) && quickFilters[quickFilter as Items].to.equals(to.endOf('day').set({ millisecond: 0 }))
        );
      }),
    [dateRange.from, dateRange.to, quickFilters],
  );

  return (
    <ToggleButtonGroup disabled={disabled} exclusive fullWidth onChange={onChange} value={value}>
      {items.map((item) => {
        const quickFilter = quickFilters[item];

        return (
          <ToggleButton key={item} sx={{ flex: quickFilter.flex }} value={item}>
            {quickFilter.label}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};
