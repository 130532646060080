import { photoApiV1 as photoApi } from '@deepup/apis';

import { fromTimestamp } from '@utils/timeFormatting';

export enum Expiration {
  expired = 0,
  available = 1,
  unknown = 2,
}

export const getLinkExpiration = (downloadLink?: photoApi.DownloadPhotoPackageResponse) => {
  if (!downloadLink || !downloadLink.expiresAt) {
    return Expiration.unknown;
  }

  return fromTimestamp(downloadLink.expiresAt).diffNow().milliseconds < 0
    ? Expiration.expired
    : Expiration.available;
};
