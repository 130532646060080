import { type Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import type { FeatureCollection } from 'geojson';

import { FetchError } from '@utils/FetchError';
import { getEnvironment } from '@utils/getEnvironment';

export type FilterDuctMetadata = {
  featureIds: string[];
} | null;

const get = async (auth: Auth0ContextInterface, filter: FilterDuctMetadata) => {
  const { apiUnified } = getEnvironment();

  const cql = filter?.featureIds.map((featureId) => `fid='${featureId}'`).join(' OR ');

  if (!cql) {
    throw new FetchError();
  }

  const token = await auth.getAccessTokenSilently();

  const response = await fetch(
    `${apiUnified}layer/v1?request=GetFeature&typeNames=deepup:duct&outputformat=JSON&cql_filter=${cql}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  const responseJson = await response.json();

  if (response.ok) {
    return responseJson;
  }

  if (!response.ok) {
    throw new FetchError(response, responseJson);
  }
};

export const useApiDuctMetadata = (filter: FilterDuctMetadata) => {
  const auth0 = useAuth0();

  return useQuery<FeatureCollection, FetchError | Error>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['useApiDuctMetadata', filter],
    queryFn: () => get(auth0, filter),
    staleTime: Infinity,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
    enabled: filter !== null,
  });
};
