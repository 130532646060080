import { photoApiV1 as photoApi, photoClientApiV1 as photoClientApi } from '@deepup/apis';
import { useMutation } from '@tanstack/react-query';

import { useGrpcRestTransport } from '@hooks/useGrpcRestTransport';

export const usePostComment = () => {
  const transport = useGrpcRestTransport();

  const mutation = useMutation<
    unknown,
    { error: { errors: string[] } },
    photoApi.CreatePhotoCommentRequest
  >({
    mutationFn: (payload) => {
      const photoClient = new photoClientApi.PhotoServiceClient(transport);

      return photoClient.createPhotoComment(payload).response;
    },
  });

  return mutation;
};
