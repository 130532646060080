import { useAuth0 } from '@auth0/auth0-react';
import { GlobalHeader } from '@deepup/global-header';
import { MapOutline, PhotoOutline, ProjectCollectionOutline, GraphOutline } from '@deepup/icons';
import { AppBar, Stack, Toolbar, useTheme } from '@mui/material';
import flagsmith from 'flagsmith';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { TabsRouter } from '@components/TabsRouter';
import { useConfig } from '@hooks/useConfig';
import { useFeatureFlagIdentity } from '@hooks/useFeatureFlagIdentity';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { trackEvent } from '@utils/trackEvent';

export const Layout = () => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth0();
  const feature_flags = flagsmith.getAllFlags();
  const { layoutContainerStyle } = useConfig();
  const theme = useTheme();
  const { isCockpitMigrationEnabled, isStatisticsEnabled } = useFeatureFlags();

  useEffect(() => {
    trackEvent('userEmailDomain', {
      email_domain: user?.email?.split('@')[1] ?? 'unknown',
    });
    trackEvent('featureFlags', {
      feature_flags,
    });
  }, [feature_flags, user]);

  // identify user in flagsmith
  useFeatureFlagIdentity(user);

  const iconProps = {
    fill: 'currentColor',
    height: '24px',
    width: '24px',
  };

  const tabItems = [];

  if (isCockpitMigrationEnabled || isStatisticsEnabled) {
    tabItems.push({
      label: t('pages.layout.mapTabLabel'),
      to: '/map',
      icon: <MapOutline {...iconProps} />,
    });
  }

  if (isCockpitMigrationEnabled) {
    tabItems.push(
      {
        label: t('pages.layout.photoTabLabel'),
        to: '/photo',
        icon: <PhotoOutline {...iconProps} />,
      },
      {
        label: t('pages.layout.projectTabLabel'),
        to: '/project',
        icon: <ProjectCollectionOutline {...iconProps} />,
      },
    );
  }

  if (isStatisticsEnabled) {
    tabItems.push({
      label: t('pages.statistics.title'),
      to: '/statistics',
      icon: <GraphOutline {...iconProps} />,
    });
  }

  return (
    <>
      <GlobalHeader
        language={i18n.language as 'de' | 'en' | undefined}
        languageSelectors={[{ key: 'de' }, { key: 'en' }]}
        onChangeLanguage={(selectedLanguage) => {
          i18n.changeLanguage(selectedLanguage);
        }}
        showLanguageToggle
        showThemeToggle
        title={t('title')}
      />
      {(isCockpitMigrationEnabled || isStatisticsEnabled) && (
        <AppBar
          component="nav"
          sx={(theme) => ({
            zIndex: theme.zIndex.drawer + 1,
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14)',
            borderRadius: 0,
            padding: 0,
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.getContrastText(theme.palette.background.paper),
            borderTop: `1px solid ${theme.palette.divider}`,
            // top because of fixed global header
            top: { xs: '56px', sm: '80px' },
          })}
        >
          <Toolbar sx={{ gap: '20px' }}>
            <TabsRouter
              items={tabItems}
              // same height as Toolbar
              sx={{ height: { xs: '56px', sm: '64px' } }}
            />
          </Toolbar>
        </AppBar>
      )}
      <Stack
        sx={{
          minHeight: '100%',
          background: theme.palette.background.default,
          color: theme.palette.text.primary,
          ...layoutContainerStyle,
        }}
      >
        <Outlet />
      </Stack>
    </>
  );
};
