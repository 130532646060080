export const getBuildEnvironment = () => {
  return {
    mapboxAccessToken: import.meta.env?.VITE_MAPBOX_ACCESS_TOKEN,
    auth0Audience: import.meta.env?.VITE_AUTH0_AUDIENCE,
    auth0ClientId: import.meta.env?.VITE_AUTH0_CLIENT_ID,
    auth0Domain: import.meta.env?.VITE_AUTH0_DOMAIN,
    wmtsSource: import.meta.env?.VITE_WMTS_SOURCE,
    wfsSource: import.meta.env?.VITE_WFS_SOURCE,
    apiConMon: import.meta.env?.VITE_API_CONMON,
    apiTrenchviewer: import.meta.env?.VITE_API_TRENCHVIEWER,
    apiUnified: import.meta.env?.VITE_API_UNIFIED,
    cssUrl: import.meta.env?.VITE_CSS_URL,
    appVersion: import.meta.env?.VITE_APP_VERSION,
    flagsmithEnvId: import.meta.env?.VITE_FLAGSMITH_ENV_ID,
    flagSmithListeningInterval: import.meta.env?.VITE_FLAGSMITH_LISTENING_INTERVAL,
    sentryDsn: import.meta.env?.VITE_SENTRY_DSN,
    sentryAuthToken: import.meta.env?.VITE_SENTRY_AUTH_TOKEN,
    dev: import.meta.env?.DEV,
    isMockingEnabled: import.meta.env?.VITE_USE_MSW === 'true',
    mode: import.meta.env?.MODE,
  };
};

export const getRuntimeEnvironment = () => {
  const env = window.__env__ || {};

  const envVars = {
    mapboxAccessToken: env?.VITE_MAPBOX_ACCESS_TOKEN,
    auth0Audience: env?.VITE_AUTH0_AUDIENCE,
    auth0ClientId: env?.VITE_AUTH0_CLIENT_ID,
    auth0Domain: env?.VITE_AUTH0_DOMAIN,
    wmtsSource: env?.VITE_WMTS_SOURCE,
    wfsSource: env?.VITE_WFS_SOURCE,
    apiConMon: env?.VITE_API_CONMON,
    apiTrenchviewer: env?.VITE_API_TRENCHVIEWER,
    apiUnified: env?.VITE_API_UNIFIED,
    cssUrl: env?.VITE_CSS_URL,
    appVersion: env?.VITE_APP_VERSION,
    flagsmithEnvId: env?.VITE_FLAGSMITH_ENV_ID,
    flagSmithListeningInterval: env?.VITE_FLAGSMITH_LISTENING_INTERVAL,
    sentryDsn: env?.VITE_SENTRY_DSN,
    sentryAuthToken: env?.VITE_SENTRY_AUTH_TOKEN,
    dev: env?.DEV,
    isMockingEnabled: env?.VITE_USE_MSW === 'true' ? true : undefined,
    mode: env?.MODE,
  };

  // Filter out entries with undefined values
  const definedVars = Object.fromEntries(
    Object.entries(envVars).filter(([, value]) => value !== undefined),
  );

  return definedVars;
};

export const getEnvironment = () => {
  const env = {
    ...getBuildEnvironment(),
    ...getRuntimeEnvironment(),
  };

  return env;
};
