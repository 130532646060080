import { useCallback } from 'react';
import {
  type NavigateOptions,
  useLocation,
  useNavigate as useNavigateRouter,
} from 'react-router-dom';

interface Path {
  pathname: string;
  search: string[][] | Record<string, string> | string | URLSearchParams;
  hash: string;
}

export type NavigateOptionsExtended = NavigateOptions & {
  preserveHash?: boolean;
  preserveSearch?: boolean;
  preserveSearchExclude?: string[];
};

interface NavigateFunction {
  (to: string | Partial<Path>, options?: NavigateOptionsExtended): void;
  (delta: number): void;
}

export const useNavigateExtended = (): NavigateFunction => {
  const navigate = useNavigateRouter();
  const location = useLocation();

  return useCallback(
    (...args) => {
      if (typeof args[0] === 'number') {
        navigate(args[0]);

        return;
      }

      const options = args[1] as undefined | NavigateOptionsExtended;

      if (typeof args[0] === 'string') {
        const pathParamsHash = args[0].split('#');
        const pathParams = pathParamsHash[0].split('?');

        const fromInput = Object.fromEntries(new URLSearchParams(pathParams[1]));
        const inUrl = options?.preserveSearch
          ? Object.fromEntries(new URLSearchParams(location.search).entries())
          : {};

        if (options?.preserveSearchExclude) {
          options.preserveSearchExclude.forEach((key) => {
            delete inUrl[key];
          });
        }

        const merged = new URLSearchParams({ ...inUrl, ...fromInput }).toString();

        navigate(
          {
            pathname: pathParams[0],
            search: merged,
            hash: pathParamsHash[1] ?? (options?.preserveHash ? window.location.hash : undefined),
          },
          args[1] ? args[1] : undefined,
        );

        return;
      }

      const fromInput = Object.fromEntries(new URLSearchParams(args[0].search));
      const inUrl = options?.preserveSearch
        ? Object.fromEntries(new URLSearchParams(location.search).entries())
        : {};

      if (options?.preserveSearchExclude) {
        options.preserveSearchExclude.forEach((key) => {
          delete inUrl[key];
        });
      }

      const merged = new URLSearchParams({ ...inUrl, ...fromInput }).toString();

      navigate(
        {
          pathname: args[0].pathname,
          search: merged,
          hash: args[0].hash ?? (options?.preserveHash ? window.location.hash : undefined),
        },
        args[1] ? args[1] : undefined,
      );
    },
    [location.search, navigate],
  );
};
