import { FilterOutline, LayersOutline, XOutline } from '@deepup/icons';
import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  CardContent,
  Chip,
  Collapse,
  Drawer,
  IconButton,
  Slider,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AccordionWithCheckbox } from '@components/Accordion';
import { FeatureFlag } from '@components/FeatureFlag';
import { SidebarHeader, SidebarHeaderText, SidebarSection } from '@components/Sidebar';
import { useConfig } from '@hooks/useConfig';
import { useFilters } from '@hooks/useFilters';
import { useMapFeatures } from '@hooks/useMapFeatures';
import { useMapZoomIsAbove } from '@hooks/useMapZoomIsAbove';
import { useMinDateFromFeatures } from '@hooks/useMinDateFromFeatures';
import { useScanDevicesFromFeatures } from '@hooks/useScanDevicesFromFeatures';
import { getNumberSelected } from '@utils/core';
import { projectCloseupThreshold } from '@utils/mapboxExpressions';

import { PhotoCategorySelect } from './PhotoCategorySelect';
import { ProjectMultiSelect } from './ProjectMultiSelect';
import { TrassesFilters } from './TrassesFilters';
import { CheckBoxGroupReversed } from './fields/CheckBoxGroup';
import { DateButtons } from './fields/DateButtons';
import { DateRangePicker } from './fields/DateRangePicker';
import { GeocoderField } from './fields/GeocoderField';
import { InverseMultiSelect, itemsToNames, namesToItems } from './fields/MultiSelect';

const minDepthSliderValue = 0;
const maxDepthSliderValue = 100;
const drawerWidth = 350;

export const FilterBox = () => {
  const { drawerStyle } = useConfig();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    scanDevices,
    setScanDevices,
    dateRange,
    setDateRange,
    showScans,
    setShowScans,
    showPhotos,
    setShowPhotos,
    photoCategories,
    setPhotoCategories,
    showPrelabels,
    setShowPrelabels,
    showTrasses,
    showPlandata,
    setShowPlandata,
    minPrelabelDepth: minDepth,
    setMinPrelabelDepth: setMinDepth,
    surfaceClassification,
    setSurfaceClassification,
  } = useFilters();
  const features = useMapFeatures(
    {
      source: 'trajectories-source',
      sourceLayer: 'artrajectory',
    },
    {
      source: 'photos-source',
      sourceLayer: 'photo',
      filter: ['to-boolean', showPhotos],
    },
    {
      source: 'prelabels-source',
      sourceLayer: 'prelabel',
      filter: ['to-boolean', showPrelabels],
    },
    {
      source: 'trass-source',
      sourceLayer: 'trass_segmented',
      filter: ['to-boolean', showTrasses],
    },
  );

  const { isZoomAboveThreshold } = useMapZoomIsAbove(projectCloseupThreshold, 1500);

  const scanDevicesAvailable = useScanDevicesFromFeatures(features, scanDevices);

  const handleDepthSliderChange = (value: number | number[]) => {
    if (!Array.isArray(value)) {
      setMinDepth(value !== minDepthSliderValue ? value : null);
    } else if (value.length !== 0) {
      setMinDepth(value[0] !== minDepthSliderValue ? value[0] : null);
    }
  };

  const availablePhotoCategories = useMemo(
    () =>
      [
        ...new Set([
          ...photoCategories,
          ...features
            .filter((feature) => feature.properties?.category_name)
            .map((feature) => feature.properties?.category_name),
        ]),
      ].sort(Intl.Collator().compare),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [features, JSON.stringify(photoCategories)],
  );

  const minDate = useMinDateFromFeatures(features);

  return (
    <>
      {isMobile && (
        <Box component="div" sx={{ margin: '1rem' }}>
          <Button onClick={() => setIsOpen(true)} variant="contained">
            {t('components.filterBox.sidebarHeaderText')}
          </Button>
        </Box>
      )}
      <Drawer
        anchor="left"
        open={isOpen}
        sx={{
          width: isMobile ? '100%' : drawerWidth,
          [`& .MuiDrawer-paper`]: {
            width: isMobile ? '100%' : drawerWidth,
            boxSizing: 'border-box',
            ...drawerStyle,
          },
        }}
        variant={isMobile ? 'temporary' : 'permanent'}
      >
        <SidebarHeader>
          <Box
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <SidebarHeaderText>{t('components.filterBox.sidebarHeaderText')}</SidebarHeaderText>
            {isMobile && (
              <IconButton onClick={() => setIsOpen(false)}>
                <XOutline />
              </IconButton>
            )}
          </Box>
        </SidebarHeader>
        <CardContent>
          <Stack direction="column" spacing="20px">
            <GeocoderField />

            <SidebarSection>
              <FilterOutline /> {t('components.filterBox.filterSubHeading')}
            </SidebarSection>
            <Box component="div">
              <FeatureFlag name="filter_projects_enabled">
                {({ BadgeSelect }) => (
                  <>
                    <BadgeSelect />
                    <ProjectMultiSelect />
                  </>
                )}
              </FeatureFlag>
            </Box>
            <Stack direction="column" spacing="12px">
              <DateButtons dateRange={dateRange} setDateRange={setDateRange} />
              <DateRangePicker
                dateRange={dateRange}
                minDate={minDate}
                setDateRange={setDateRange}
              />
            </Stack>
            <Box component="div">
              <InverseMultiSelect
                disabled={!scanDevicesAvailable.length}
                helperText={
                  !scanDevicesAvailable.length && isZoomAboveThreshold
                    ? t('components.filterBox.scanDeviceSelect.helperText')
                    : undefined
                }
                items={scanDevicesAvailable}
                label={t('components.filterBox.scanDeviceSelect.label')}
                setUnselectedItems={(items) => setScanDevices(itemsToNames(items))}
                unselectedItems={namesToItems(scanDevices)}
              />
            </Box>

            <SidebarSection>
              <LayersOutline /> {t('components.filterBox.mapLayers.subHeading')}
            </SidebarSection>

            <Collapse in={!isZoomAboveThreshold} unmountOnExit>
              <Stack direction="row" spacing="12px">
                <InfoOutlined />
                <Typography>{t('components.filterBox.mapLayers.infoText')}</Typography>
              </Stack>
            </Collapse>

            <Box component="div">
              <AccordionWithCheckbox
                checked={showScans}
                collapsable
                onCheck={setShowScans}
                postTitle={
                  <Tooltip title={t('components.filterBox.mapLayers.scans.tooltipBeta')}>
                    <Chip
                      color="primary"
                      label="BETA"
                      sx={(theme) => ({
                        backgroundColor: theme.palette.info.main,
                        fontWeight: theme.typography.fontWeightBold,
                      })}
                    />
                  </Tooltip>
                }
                title="Scans"
              >
                <CheckBoxGroupReversed
                  availableItems={['trench', 'surface', 'undefined']}
                  disabled={!showScans}
                  items={surfaceClassification}
                  setItems={setSurfaceClassification}
                  transformLabel={(label) =>
                    t(
                      `components.filterBox.surfaceClassification.${label as 'surface' | 'trench' | 'undefined'}`,
                    )
                  }
                />
              </AccordionWithCheckbox>

              <AccordionWithCheckbox
                checked={showPhotos}
                onCheck={setShowPhotos}
                postTitle={
                  !showPhotos ? null : (
                    <Chip
                      color="primary"
                      label={`${
                        !photoCategories.length
                          ? t('components.filterBox.mapLayers.photos.activeInfo.all')
                          : getNumberSelected(availablePhotoCategories, photoCategories)
                      } ${t('components.filterBox.mapLayers.photos.activeInfo.active')}`}
                    />
                  )
                }
                title={t('components.filterBox.mapLayers.photos.title')}
              >
                <PhotoCategorySelect
                  availablePhotoCategories={availablePhotoCategories}
                  photoCategories={photoCategories}
                  setPhotoCategories={setPhotoCategories}
                />
              </AccordionWithCheckbox>

              <FeatureFlag name="prelabels_enabled">
                {({ Badge }) => (
                  <AccordionWithCheckbox
                    checked={showPrelabels}
                    onCheck={setShowPrelabels}
                    postTitle={
                      !showPrelabels || minDepth === null ? null : (
                        <Chip color="primary" label={`aktiv`} />
                      )
                    }
                    title={<Badge>{t('components.filterBox.mapLayers.prelabels.title')}</Badge>}
                  >
                    <Stack direction="column" spacing="12px">
                      <Typography variant="body1">
                        {t('components.filterBox.mapLayers.prelabels.minDepth')}
                      </Typography>
                      <Stack direction="column" spacing={0} sx={{ width: '100%' }}>
                        <Slider
                          color="secondary"
                          disabled={!showPrelabels}
                          getAriaLabel={() =>
                            t('components.filterBox.mapLayers.prelabels.minDepth')
                          }
                          getAriaValueText={(v) => `${v}cm`}
                          marks
                          max={maxDepthSliderValue}
                          min={minDepthSliderValue}
                          onChange={(_, newValue) => handleDepthSliderChange(newValue)}
                          size="small"
                          step={5}
                          value={minDepth ?? minDepthSliderValue}
                          valueLabelDisplay="auto"
                        />
                        <Stack direction="row">
                          <Typography color="secondary" variant="body2">
                            {minDepthSliderValue}cm
                          </Typography>
                          <Box component="div" sx={{ width: '100%' }} />
                          <Typography color="secondary" variant="body2">
                            {maxDepthSliderValue}cm
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </AccordionWithCheckbox>
                )}
              </FeatureFlag>

              <FeatureFlag name="trasses_enabled">
                {({ Badge }) => (
                  <TrassesFilters
                    title={<Badge>{t('components.filterBox.mapLayers.trass.title')}</Badge>}
                  />
                )}
              </FeatureFlag>

              <FeatureFlag name="plandata_enabled">
                {({ Badge }) => (
                  <AccordionWithCheckbox
                    checked={showPlandata}
                    collapsable={false}
                    onCheck={setShowPlandata}
                    title={<Badge>{t('components.filterBox.mapLayers.planData.title')}</Badge>}
                  />
                )}
              </FeatureFlag>
            </Box>
          </Stack>
        </CardContent>
      </Drawer>
    </>
  );
};
