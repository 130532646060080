import { labelingProgressApiV1 as labelingProgressApi } from '@deepup/apis';
import { ArrowDownLine } from '@deepup/icons';
import { Alert, Box, CircularProgress, IconButton, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DateTime } from 'luxon';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DateButtons } from '@components/FilterBox/fields/DateButtons';
import { DateRangePicker } from '@components/FilterBox/fields/DateRangePicker';
import { MultiSelect } from '@components/FilterBox/fields/MultiSelect';
import { SingleSelect } from '@components/FilterBox/fields/SingleSelect';
import { ToggleButtonGroup } from '@components/FilterBox/fields/ToggleButtonGroup';
import { LoadingScreen } from '@components/LoadingScreen';
import {
  useApiGetTimeSeries,
  useApiDownloadTimeSeries,
  useApiGetTotalProgress,
  useApiDownloadTotalProgress,
  useApiGetDownloadStatus,
} from '@hooks/useApiLabelingProgress';
import { useApiOrganizations, useApiProjectsDeprecated } from '@hooks/useApiOMa';
import { useFilters } from '@hooks/useFilters';
import { downloadFile } from '@utils/downloadFile';

import { ProgressTable } from './ProgressTable';
import { TimeSeriesChart } from './TimeSeriesChart';

export const Page = () => {
  const { t } = useTranslation();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [download, setDownload] = useState<{
    id: string;
    type: 'totalProgress' | 'timeSeries';
  } | null>(null);

  const {
    organizationId,
    setOrganizationId,
    projectList,
    setProjectList,
    dateRange,
    setDateRange,
    interval,
    setInterval,
  } = useFilters();
  const { data: dataOrganizations, error: errorOrganizations } = useApiOrganizations();
  const { data: dataProjects, error: errorProjects } = useApiProjectsDeprecated({
    organizationId,
    permission: 'monitor',
    size: 1000,
  });
  // TODO: change to new API if permission can be requested and data leak is fixed
  // const { data: dataProjects, error: errorProjects } = useApiProjects(organizationId);

  const {
    data: dataTotalProgress,
    isLoading: isLoadingTotalProgress,
    error: errorTotalProgress,
  } = useApiGetTotalProgress({
    organizationId,
    dateRange,
    projectIds: projectList,
    listOptions: {
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
    },
  });
  const {
    mutate: downloadTotalProgress,
    error: errorTotalProgressDownload,
    isPending: isPendingDownloadTotalProgress,
  } = useApiDownloadTotalProgress();
  const {
    data: dataTimeSeries,
    error: errorTimeSeries,
    hasNextPage,
    fetchNextPage,
    isLoading: isLoadingTimeSeries,
  } = useApiGetTimeSeries({
    organizationId,
    dateRange,
    projectIds: projectList,
    interval,
    pageSize: 20,
  });
  const {
    mutate: downloadTimeSeries,
    error: errorTimeSeriesDownload,
    isPending: isPendingDownloadTimeSeries,
  } = useApiDownloadTimeSeries();

  const { data: dataDownloadStatus, error: errorDownloadStatus } = useApiGetDownloadStatus({
    id: download?.id,
  });

  const { organizations, selectedOrganization } = useMemo(() => {
    const organizations = dataOrganizations?.items.map((org) => ({ id: org.id, name: org.name }));

    return {
      organizations,
      selectedOrganization: organizations?.find((p) => p.id === organizationId),
    };
  }, [dataOrganizations, organizationId]);

  const { projects, selectedProjects } = useMemo(() => {
    const projects = dataProjects?.items.map((project) => ({
      id: project.id,
      name: project.name,
    }));

    return {
      projects,
      selectedProjects: projects?.filter((p) => projectList.includes(p.id)),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProjects, JSON.stringify(projectList)]);

  // set organizationId if only 1 organization available
  useEffect(() => {
    if (organizations?.length === 1) {
      setOrganizationId(organizations[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(organizations)]);

  // reset projects, if org change
  // TODO: find a way to not reset this url param on mount
  useEffect(() => {
    setProjectList([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  // reset page to 0
  // TODO: prevent double fetch with old page
  const dateRangeFrom = dateRange.from?.toISO();
  const dateRangeTo = dateRange.to?.toISO();
  const projectListJson = JSON.stringify(projectList);

  useEffect(() => {
    setPaginationModel((prevState) => ({ ...prevState, page: 0 }));
  }, [organizationId, dateRangeFrom, dateRangeTo, projectListJson, paginationModel.pageSize]);

  // automatically fetch all timeseries pages
  useEffect(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage, dataTimeSeries]);

  // merge dataTimeSeries
  const timeSeriesItems = useMemo(
    () => dataTimeSeries?.pages.flatMap((page) => page.items),
    [dataTimeSeries],
  );

  // trigger downloads
  useEffect(() => {
    if (dataDownloadStatus?.fileUrl) {
      downloadFile(dataDownloadStatus.fileUrl);
    }
  }, [dataDownloadStatus]);

  // show error
  const error =
    errorOrganizations ??
    errorProjects ??
    errorTotalProgress ??
    errorTotalProgressDownload ??
    errorTimeSeries ??
    errorTimeSeriesDownload ??
    errorDownloadStatus;

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error.body?.message ?? 'Unknown Error', { variant: 'error' });
    }
  }, [error]);

  if (!dataOrganizations) {
    return (
      <Stack sx={{ flex: 1 }}>
        <LoadingScreen />
      </Stack>
    );
  }

  return (
    <Stack spacing={2} useFlexGap>
      <Grid container spacing={2}>
        {(organizations?.length ?? 0) > 1 && (
          <Grid xs={3}>
            <SingleSelect
              items={organizations ?? []}
              label={t('pages.statistics.labelingProgress.selectOrganization.label')}
              selectedItem={selectedOrganization ?? null}
              setSelectedItem={(org) => {
                setOrganizationId(org?.id ?? null);
              }}
            />
          </Grid>
        )}
        {dataProjects && (
          <Grid xs={3}>
            <MultiSelect
              items={projects ?? []}
              label={t('pages.statistics.labelingProgress.selectProjects.label')}
              selectedItems={selectedProjects ?? []}
              setSelectedItems={(projects) => {
                setProjectList(projects.map((project) => project.id));
              }}
            />
          </Grid>
        )}
        <Grid md={3} xs={6}>
          <DateRangePicker
            dateRange={dateRange}
            maxDate={DateTime.now().minus({ days: 1 })}
            minDate={DateTime.fromSQL('2024-03-01')}
            setDateRange={(dateRange) => {
              setDateRange(dateRange);
            }}
          />
        </Grid>
        <Grid md={3} xs={6}>
          <DateButtons
            dateRange={dateRange}
            items={['lastMonth', 'currentMonth']}
            setDateRange={(dateRange) => {
              setDateRange(dateRange);
            }}
          />
        </Grid>
      </Grid>

      {(!organizationId || !dateRange.from || !dateRange.to) && (
        <Alert severity="info">{t('pages.statistics.labelingProgress.pleaseSelect')}</Alert>
      )}

      {organizationId &&
        dateRange.from &&
        dateRange.to &&
        !isLoadingTotalProgress &&
        !dataTotalProgress?.items.length && (
          <Alert severity="warning">{t('pages.statistics.labelingProgress.noData')}</Alert>
        )}

      {(isLoadingTotalProgress || !!dataTotalProgress?.items.length) && (
        <Box component="div">
          <Stack direction="row" justifyContent="flex-end">
            <IconButton
              disabled={
                isPendingDownloadTotalProgress ||
                dataDownloadStatus?.status === labelingProgressApi.FileStatus.PENDING
              }
              onClick={() =>
                downloadTotalProgress(
                  {
                    organizationId,
                    dateRange,
                    projectIds: projectList,
                  },
                  {
                    onSuccess: (data) =>
                      setDownload({ id: data.downloadId, type: 'totalProgress' }),
                  },
                )
              }
            >
              {isPendingDownloadTotalProgress ||
              (dataDownloadStatus?.status === labelingProgressApi.FileStatus.PENDING &&
                download?.type === 'totalProgress') ? (
                <CircularProgress size="24px" />
              ) : (
                <ArrowDownLine />
              )}
            </IconButton>
          </Stack>
          <ProgressTable
            isLoading={isLoadingTotalProgress}
            onPaginationModelChange={setPaginationModel}
            paginationMode="server"
            paginationModel={
              dataTotalProgress?.paginationInfo
                ? {
                    page: Number(dataTotalProgress.paginationInfo.page),
                    pageSize: Number(dataTotalProgress.paginationInfo.pageSize),
                  }
                : undefined
            }
            progress={dataTotalProgress?.items ?? []}
            totalItems={dataTotalProgress?.paginationInfo?.totalItems}
          />
        </Box>
      )}

      {(isLoadingTimeSeries || !!timeSeriesItems?.length) && (
        <Stack spacing={1} useFlexGap>
          <Grid container spacing={2}>
            <Grid md={4} xs={12}></Grid>
            <Grid md={4} xs={12}>
              <ToggleButtonGroup<number>
                setValue={setInterval}
                value={interval}
                values={[
                  { value: 0, label: t('pages.statistics.DAILY') },
                  { value: 1, label: t('pages.statistics.WEEKLY') },
                  { value: 2, label: t('pages.statistics.MONTHLY') },
                ]}
              />
            </Grid>
            <Grid md={4} xs={12}>
              <Stack direction="row" justifyContent="flex-end">
                <IconButton
                  disabled={
                    isPendingDownloadTimeSeries ||
                    dataDownloadStatus?.status === labelingProgressApi.FileStatus.PENDING
                  }
                  onClick={() => {
                    downloadTimeSeries(
                      {
                        organizationId,
                        dateRange,
                        projectIds: projectList,
                        interval,
                      },
                      {
                        onSuccess: (data) =>
                          setDownload({ id: data.downloadId, type: 'timeSeries' }),
                      },
                    );
                  }}
                >
                  {isPendingDownloadTimeSeries ||
                  (dataDownloadStatus?.status === labelingProgressApi.FileStatus.PENDING &&
                    download?.type === 'timeSeries') ? (
                    <CircularProgress size="24px" />
                  ) : (
                    <ArrowDownLine />
                  )}
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
          <TimeSeriesChart
            interval={interval}
            isLoading={isLoadingTimeSeries}
            items={timeSeriesItems}
          />
        </Stack>
      )}
    </Stack>
  );
};
