import { timestampApi, labelingProgressApiV1 as labelingProgressApi } from '@deepup/apis';
import { useDeepUpTheme } from '@deepup/mui-theme-deepup';
import { Stack, Typography } from '@mui/material';
import type { BarItemIdentifier } from '@mui/x-charts';
import { BarChart } from '@mui/x-charts/BarChart';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProgressTable } from './ProgressTable';

const formats = {
  [labelingProgressApi.Interval.DAILY]: 'D',
  [labelingProgressApi.Interval.WEEKLY]: 'nn',
  [labelingProgressApi.Interval.MONTHLY]: 'MMM',
};

const timestampToIntervalString = (
  timestamp: timestampApi.Timestamp | undefined,
  interval: labelingProgressApi.Interval,
  locale: string,
) => {
  const date = timestamp ? timestampApi.Timestamp.toDate(timestamp) : null;
  const dateTime = date ? DateTime.fromJSDate(date) : null;

  if (!dateTime || !dateTime.isValid) {
    return '';
  }

  return dateTime.setLocale(locale).toFormat(formats[interval]);
};

export const TimeSeriesChart = ({
  items,
  interval,
  isLoading,
}: {
  items?: labelingProgressApi.GetTimeSeriesResponse['items'];
  interval: labelingProgressApi.Interval;
  isLoading: boolean;
}) => {
  const { i18n } = useTranslation();
  const [progress, setProgress] = useState<
    labelingProgressApi.GetTotalProgressResponse['items'] | undefined
  >(undefined);
  const [selectedInterval, setSelectedInterval] = useState<string | null>(null);
  const { t } = useTranslation();
  const { theme } = useDeepUpTheme();

  // remove progress table on data change
  useEffect(() => {
    setProgress(undefined);
    setSelectedInterval(null);
  }, [items, interval]);

  const dataSeries =
    items?.[0]?.intervals.map((_, index) => {
      return items.reduce<number>((acc, curr) => {
        return acc + (curr.intervals[index]?.progress?.totalMeters ?? 0);
      }, 0);
    }) ?? [];

  const dataXAxis =
    items?.[0]?.intervals.map((item) => {
      return timestampToIntervalString(item.startsAt, interval, i18n.language);
    }) ?? [];

  const onItemClick = (_: unknown, barItemIdentifier: BarItemIdentifier) => {
    if (isLoading) {
      return;
    }

    const progress = items?.map((item) => ({
      project: item.project,
      progress: item.intervals[barItemIdentifier.dataIndex]?.progress,
    }));

    setProgress(progress);
    setSelectedInterval(
      timestampToIntervalString(
        items?.[0]?.intervals[barItemIdentifier.dataIndex]?.startsAt,
        interval,
        i18n.language,
      ),
    );
  };

  const selectedIntervalText = {
    [labelingProgressApi.Interval.DAILY]: t('pages.statistics.labelingProgress.selectedDay'),
    [labelingProgressApi.Interval.WEEKLY]: t('pages.statistics.labelingProgress.selectedWeek'),
    [labelingProgressApi.Interval.MONTHLY]: t('pages.statistics.labelingProgress.selectedMonth'),
  };

  return (
    <Stack>
      <BarChart
        borderRadius={theme.shape.borderRadius}
        grid={{ horizontal: true }}
        height={280}
        // https://mui.com/x/react-charts/axis/#fixing-overflow-issues
        margin={{ left: 75 }}
        onItemClick={onItemClick}
        series={[
          {
            data: dataSeries,
            color: '#008066',
            valueFormatter: (val) => t('decimal', { val }),
          },
        ]}
        xAxis={[
          {
            id: 'barDates',
            data: dataXAxis,
            scaleType: 'band',
          },
        ]}
      />
      {progress && selectedInterval && (
        <>
          <Typography variant="subtitle2">
            {selectedIntervalText[interval]} {selectedInterval}
          </Typography>
          <ProgressTable paginationMode="client" progress={progress} />
        </>
      )}
    </Stack>
  );
};
